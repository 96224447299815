import * as MUIDialog from "@mui/material";
import { ReactElement, useEffect, useState } from "react";

const Dialog = ({ children, title, buttons, open=false, onCancel, ...props }: any): ReactElement => {

  const [localOpen, setLocalOpen] = useState(true);
  const [dButtons, setDButtons]: [{text: string, action: Function, sx?: any}[], any] = useState([]);

  useEffect(() => {
    if(!buttons) {
      setDButtons([{
        text: 'Close',
        action: (e:any) => {
          setLocalOpen(false)
          if(onCancel) {
            onCancel()
            setLocalOpen(true)
          }
        }
      }]);
      return;
    }
    buttons = buttons.map((button: any) => ({...button, sx: {fontWeight: 'bold', ...(button.sx ?? {})}}));
    setDButtons(buttons);
  }, [buttons]);

  return <MUIDialog.Dialog open={open && localOpen} {...props}>
    <div className="p-4">
      {
        title && <div className="pb-4 text-xl font-semibold">
          {title}
        </div>
      }
      <div className="p-2" style={{minHeight: '100px'}}>
        {children}
      </div>
      <MUIDialog.DialogActions>
        {
          dButtons && dButtons.map((button, key) => <MUIDialog.Button
            onClick={(e) => button.action(e)}
            key={`d-button-${key}`}
            sx={button.sx ?? {} }
            className="text-red-600"
          >
            {button.text}
          </MUIDialog.Button>)
        }
      </MUIDialog.DialogActions>
    </div>
  </MUIDialog.Dialog>
}
export default Dialog;