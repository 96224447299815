import { Button } from "@mui/material";
import useFilings from "../../../hooks/useFilings";

interface SyncFilingsButton {
  afterSync?: Function
}

const SyncFilingsButton = ({afterSync, ...props}: SyncFilingsButton) => {
  const { syncFilings } = useFilings();

  const onSyncFilings = async () => {
    await syncFilings();
    afterSync && afterSync();
  }

  return <Button
    {...props}
    variant="outlined"
    sx={{
      border: "solid 1px #5261A0",
      backgroundColor: "white",
      // marginBottom: "16px",
      color: "#5261A0",
      paddingX: "32px",
      textTransform: "none",
      "&:hover": {
        backgroundColor: "#5261A0",
        color: "white",
      },
    }}
    onClick={onSyncFilings}
  >
    Sync Filings
  </Button>
};

export default SyncFilingsButton;
