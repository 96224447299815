import Close from '@mui/icons-material/Close';
import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import RoundedBox from '../../layout/RoundedBox';
import FilingFullDetails from '../../filings/details/FilingFullDetails';
import DialogCloseable from '../../dialogs/DialogCloseable';

interface FilingInfo {
  caseName: string;
  courtName: string;
  filingStatus: string;
  envelope: string;
  caseCategory: string;
  caseType: string;
  filingType: string;
  filingTime: string;
  filingDescription: string;
  clientMatterNumber: string;
  filerName: string;
  email: string;
  firmName: string;
  address: string;
  phoneNumber: string;
  accountName: string;
  waiverAccount: string;
  documents: { documentType: string; fileName: string; description: string }[];
  fees: { serviceFees: string; taxFees: string; filingFee: string; filingServicesFee: string; courtFeesTotal: string };
}

const PreviewSection = ({ title, content }: { title: string, content: React.ReactNode }) => (
  <Box>
    <Box sx={{
      backgroundColor: '#E7EDFF',
      px: 2,
      py: 1
    }}>
      <Typography sx={{ fontWeight: 500, fontSize: 14 }}>{title}</Typography>
    </Box>
    {content}
  </Box>
);

interface FilingInformationProps {
  filingInfo: FilingInfo
}

// Fee Information Section
const FilingInformation: React.FC<FilingInformationProps> = ({filingInfo}: FilingInformationProps) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  if(!filingInfo) {
    return;
  }

  return (
    <div className='py-6'>
      <h3 className="text-md mb-4 text-[#5261A0] font-medium">Filing Information:</h3>
      <RoundedBox className="bg-white px-10 py-12">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <PreviewSection
              title="Case Information"
              content={
                <Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 2,
                      }}>
                        <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Location</Typography>
                        <Typography sx={{ fontSize: 14 }}>{filingInfo.courtName}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 2,
                        backgroundColor: '#F7F9FC'
                      }}>
                        <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Court | Calendar</Typography>
                        <Typography sx={{ fontSize: 14 }}>{filingInfo.courtName}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 2,
                      }}>
                        <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Envelope</Typography>
                        <Typography sx={{ fontSize: 14 }}>{filingInfo.envelope}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <PreviewSection
              title="Filing Information"
              content={
                <Box>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 2,
                      }}>
                        <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Type</Typography>
                        <Typography sx={{ fontSize: 14 }}>{filingInfo.filingType}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 2,
                        backgroundColor: '#F7F9FC'
                      }}>
                        <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Submission Time</Typography>
                        <Typography sx={{ fontSize: 14 }}>{filingInfo.filingTime}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 2,
                      }}>
                        <Typography sx={{ color: 'text.secondary', fontSize: 14 }}>Filing Description</Typography>
                        <Typography sx={{ fontSize: 14 }}>{filingInfo.filingDescription}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              }
            />
          </Grid>
        </Grid>
      </RoundedBox>
      <div className="flex justify-center mt-4">
        <Button
          sx={{
            backgroundColor: "#E5EBF8",
            color: "#5261A0",
            textTransform: "capitalize",
            fontSize: 14,
            paddingX: 8,
            borderRadius: "100px",
          }}
          onClick={openDialog}
        >
          View All Filing Information
        </Button>
      </div>
      {
        <DialogCloseable
          open={isDialogOpen}
          onClose={() => setIsDialogOpen(false)}
          width="1200px"
        >
          <FilingFullDetails filingInfo={filingInfo} />
        </DialogCloseable>
      }
    </div>
  );
};

export default FilingInformation;
