import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";

interface CustomTablePaginationProps {
  rows: any[];
  loading: boolean;
  variant?: "default" | "primary";
  dataFetcher?: Function
}

const CustomTablePagination: React.FC<CustomTablePaginationProps> = ({
  rows = [],
  loading = false,
  variant = "default",
  dataFetcher
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return <div className="MuiTablePagination-root css-jtlhu6-MuiTablePagination-root">
    <div className="MuiToolbar-root MuiToolbar-gutters MuiToolbar-regular MuiTablePagination-toolbar css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar">
      <div className="MuiTablePagination-spacer css-1psng7p-MuiTablePagination-spacer"></div>
      <p className="MuiTablePagination-selectLabel css-pdct74-MuiTablePagination-selectLabel" id=":rlb:">Rows per page:</p>
      <div className="MuiInputBase-root MuiInputBase-colorPrimary MuiTablePagination-input css-16c50h-MuiInputBase-root-MuiTablePagination-select">
        <div role="combobox" aria-controls=":rld:" aria-expanded="false" aria-haspopup="listbox" aria-labelledby=":rlb: :rla:" id=":rla:" className="MuiSelect-select MuiTablePagination-select MuiSelect-standard MuiInputBase-input css-194a1fa-MuiSelect-select-MuiInputBase-input">
          5
        </div>
        <input aria-invalid="false" aria-hidden="true" className="MuiSelect-nativeInput css-yf8vq0-MuiSelect-nativeInput" value="5" />
        <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiTablePagination-selectIcon MuiSelect-iconStandard css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="ArrowDropDownIcon"><path d="M7 10l5 5 5-5z"></path></svg>
      </div>
      <p className="MuiTablePagination-displayedRows css-levciy-MuiTablePagination-displayedRows">
        6–10 of 10
      </p>
      <div className="MuiTablePagination-actions">
        <button className="MuiButtonBase-root MuiIconButton-root MuiIconButton-colorInherit MuiIconButton-sizeMedium css-zylse7-MuiButtonBase-root-MuiIconButton-root" type="button" aria-label="Go to previous page" title="Go to previous page">
          <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="KeyboardArrowLeftIcon"><path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path></svg>
          <span className="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span>
        </button>
        <button className="MuiButtonBase-root Mui-disabled MuiIconButton-root Mui-disabled MuiIconButton-colorInherit MuiIconButton-sizeMedium css-zylse7-MuiButtonBase-root-MuiIconButton-root" tabindex="-1" type="button" aria-label="Go to next page" title="Go to next page">
          <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="KeyboardArrowRightIcon"><path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z"></path></svg>
        </button>
      </div>
    </div>
  </div>
};

export default CustomTablePagination;
