import React, { useEffect, useState } from "react";
import { Select, MenuItem, FormControl, Button } from "@mui/material";
import { useDebounce } from "../../../../hooks/useDebounce";
import { UserFilters } from "../../../../hooks/useUsers";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../hooks/auth";
import FilterSearchBar from "../../../../components/FilterSearchBar";

interface FilterUsersProps {
  onChange: (filters: UserFilters) => void
  onSyncUsers?: Function
  onCreateUser?: Function
}

const FilterUsers: React.FC<FilterUsersProps> = ({ onChange, onSyncUsers }) => {
  const [search, setSearch] = useState<UserFilters["search"]>("");
  const [role, setRole] = useState<UserFilters["type"]>("all");
  const navigation = useNavigate();
  const { hasRole } = useAuth({ middleware: 'auth' });

  const debouncedRole = useDebounce(role, 500);

  useEffect(() => {
    const options: any = {
      search: search ? search : '',
      type: debouncedRole && debouncedRole != 'all' ? debouncedRole : ''
    };
    onChange(options);
  }, [search, debouncedRole]);

  const handleCreateUser = () => {
    navigation("/admin-dashboard/user-management/create-user");
  };

  // Roles for filtering
  const roles = {
    all: "All",
    // attorney: "Attorney",
    firm_admin: "Firm Admin",
    firm_user: "Filer",
  };

  return (
    <div>
      <FilterSearchBar onChange={setSearch} placeholder="Search by Name, Email ID..."/>
      <div className="flex justify-between items-center w-full my-4">
        <div className="flex flex-initial">
          <div className="flex items-center space-x-2 rounded-full px-4 border-1 max-h-10">
            <span className="font-bold text-base text-[#757575]">Filter By Role</span>
            <FormControl variant="outlined">
              <Select
                labelId="role-label"
                value={role}
                onChange={(e) => {
                  setRole(e.target.value);
                }}
                sx={{
                  borderRadius: "50px",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
              >
                {Object.entries(roles).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="flex-1 flex justify-end gap-4">
          {
            onSyncUsers && hasRole('admin') && <Button
              variant="outlined"
              sx={{
                border: "solid 1px #5261A0",
                backgroundColor: "white",
                // marginBottom: "16px",
                color: "#5261A0",
                paddingX: "32px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#5261A0",
                  color: "white",
                },
              }}
              onClick={() => onSyncUsers()}
            >
              Sync Users
            </Button>
          }
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#5261A0",
              color: "white",
              textTransform: "none",
            }}
            onMouseDown={handleCreateUser}
          >
            Add New User
          </Button>
        </div>
      </div>
    </div>
  );
};

export default FilterUsers;
