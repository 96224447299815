import { DeleteOutlined, EditOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFilings from '../../../hooks/useFilings';
import RoundedBox from '../../../components/layout/RoundedBox';
import AgTable, { PaginationDataRequest } from '../../../components/table/AgTable';
import dayjs from 'dayjs';
import StatusChip from '../../../components/table/StatusChip';
import DialogDelete from '../../../components/dialogs/DialogDelete';
import { DateRange, DateRangeInput } from '../../../components/Inputs/DateRangeInput';
import FilingsFilterInput, { FilingsFilter } from '../../../components/Inputs/FilingsFilterInput';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FilterSearchBar from '../../../components/FilterSearchBar';

interface FilingToDelete {
  filingId: string;
  caseId: string;
}

const DraftFilingsTab = () => {
  const { pagination, fetchCaseMyDraftFilings, deleteFiling } = useFilings();
  const navigate = useNavigate();

  useEffect(() => {
    fetchCaseMyDraftFilings();
  }, []);

  const handleViewFiling = (data: any) => {
    navigate(`${data.id}`);
  };

  const handleEditFiling = (data: any) => {
    if (data.filing_type == 'new-case') {
      navigate(`/file-case/${data.filing_case.id}`);
    } else {
      navigate(`/file-into-existing-case/${data.filing_case.id}`);
    }
  };

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [idToDelete, setIdToDelete] = useState<FilingToDelete | null>(null);
  const handleDeleteFiling = (data: any) => {
    setShowConfirmDelete(true);
    setIdToDelete({
      filingId: data.id,
      caseId: data.filing_case.id
    });
    console.log('Deleting filing with ID: ', data.id);
  };
  const cancelDelete = () => {
    setIdToDelete(null);
    setShowConfirmDelete(false);
  };
  const confirmDelete = async () => {
    setShowConfirmDelete(false);
    if (idToDelete?.caseId && idToDelete?.filingId) {
      await deleteFiling(idToDelete.caseId, idToDelete.filingId);
      fetchCaseMyDraftFilings();
    }
  };

  const actions = (status: any) => {
    const baseActions = [
      // {
      //   label: 'View',
      //   onClick: handleViewFiling,
      //   icon: <VisibilityOutlined className="text-base-blue" />,
      // }
    ];
    baseActions.push({
      label: 'Edit',
      onClick: handleEditFiling,
      icon: <EditOutlined className="text-base-blue" />,
    });
    baseActions.push({
      label: 'Delete',
      onClick: handleDeleteFiling,
      icon: <DeleteOutlined className="text-base-blue" />,
    });

    return baseActions;
  };

  const colDef: Array<Object> = [
    { field: 'id', headerName: 'Draft ID', flex: 1 },
    { field: 'filing_case.title', headerName: 'Case Title', flex: 1 },
    { field: 'client_number', headerName: 'Client Matter N' },
    {
      field: 'filing_case.filing_status',
      headerName: 'Filing Status',
      cellRenderer: (data: any) => (
        <StatusChip status={data.value} type='borderLess' />
      ),
    },
    {
      field: 'updated_at',
      headerName: 'Last Update',
      valueFormatter: (params: any) => {
        return dayjs(params.value).format('MM/DD/YYYY, HH:mm');
      }
    },
    {
      field: 'filing_case.filing_status.code',
      headerName: 'Actions',
      maxWidth: 120,
      cellRenderer: (data: any) => (
        <div className="flex space-x-2">
          {actions(data.value).map((action: any, index: number) => {
            return (
              <Tooltip key={index} title={action.label} arrow>
                <button onClick={() => action.onClick(data.data)}>
                  {action.icon}
                </button>
              </Tooltip>);
          })}
        </div>
      )
    },
  ]

  const onSearchChange = (search: string) => {
    return fetchCaseMyDraftFilings({ ...pagination, currentPage: 1, search });
  }

  const onDateChange = (dateRange: DateRange) => {
    return fetchCaseMyDraftFilings({ ...pagination, ...dateRange, currentPage: 1 });
  }

  const onFilterChange = (filters: FilingsFilter) => {
    return fetchCaseMyDraftFilings({ ...pagination, ...filters, currentPage: 1 });
  }

  const onPaginationChange = (options: PaginationDataRequest) => {
    return fetchCaseMyDraftFilings({ ...pagination, ...options });
  }

  return <>
    <RoundedBox className="px-10 py-6 border-2 border-base-blue rounded-2xl mb-8">
      <Typography className="text-black">
        Only drafts saved in the last 30 days will be shown. If your status is "Submission Failed", click the hyperlink to view the reason for the failure. If the reason is "Unknown Error" or "Unknown Exception Error", please initiate the filing process anew, as we have updated the state's code, and you may encounter a loop. For further inquiries, please reach out to Customer Support.
      </Typography>
    </RoundedBox>
    <div className="flex flex-col space-y-4 mb-8">
      <FilterSearchBar onChange={onSearchChange} placeholder="Search by Client Matter N, Case N, Case Title, or Court Location..."/>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <DateRangeInput onChange={onDateChange} value={pagination} />
            <FilingsFilterInput onChange={onFilterChange} value={pagination} filters={['clientMatterNumber', 'attorneyId']}/>
          </div>
        </div>
      </LocalizationProvider>
    </div>
    <div className="mb-8">
      <AgTable
        data={pagination.data}
        colDef={colDef}
        pagination={pagination}
        fetchMethod={onPaginationChange}
      />
    </div>
    <DialogDelete
      // title="Are you sure you want to delete this filing?"
      open={showConfirmDelete}
      cancel={cancelDelete}
      confirm={confirmDelete}
    >
      <p>Are you sure you want to <b>delete</b> this filing?</p>
    </DialogDelete>
  </>
};

export default DraftFilingsTab;
