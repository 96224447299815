import React from "react";
import { colors, getKey, getText } from "../../../pages/my-filings/util";

interface StatusChipProps {
  status: string;
  type: 'borderLess' | 'bgLess';
}

const StatusChip = ({ status, type }: StatusChipProps) => {

  let style = '';
  switch (type) {
    case 'bgLess':
      style = `bg-none border border-${colors[getKey(status)]} text-${colors[getKey(status)]}`;
      break;
    case 'borderLess':
      style = `bg-${colors[getKey(status)]} text-white`;
      break;
  };
  return (
    <span className={`px-2 py-1 font-semibold rounded-xl ${style}`}>
      {getText(status)}
    </span>
  );
};

export default React.memo(StatusChip);