import { Button } from '@mui/material';
import RoundedBox from '../../layout/RoundedBox';
import { bgColorByStatus, textColorByStatus } from '../../../pages/my-filings/util';
import { dateHour, FilingStatus } from '../../../utils/utils';
import { useState } from 'react';
import { useAuth } from '../../../hooks/auth';
import { useNavigate } from 'react-router-dom';

interface CaseUpdatesProps {
  caseNumber?: string
  statuses: FilingStatus[]
}

const CaseUpdates: React.FC<CaseUpdatesProps> = ({ caseNumber, statuses }) => {

  const initialStatusesLength = 4;
  const [statusToShow, setStatusToShow] = useState(initialStatusesLength);
  const { hasRole } = useAuth({middleware: 'auth'})
  const navigate = useNavigate();
  
  const handleChangePaymentAccount = () => {
    navigate('/admin-dashboard/payment-accounts');
  };
  
  const handleViewAllUpdates = () => {
    setStatusToShow(statusToShow == initialStatusesLength ? 1000000000 : initialStatusesLength);
  }

  if(!statuses[0]) {
    return <></>
  }

  return (
    <div className="py-6">
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-md font-semibold">
            Case {caseNumber}, <span className={textColorByStatus(statuses[0]?.short_name)}>{statuses[0]?.name}</span> at {statuses[0]?.pivot.created_at && dateHour(new Date(statuses[0]?.pivot.created_at))}
          </h2>
        </div>
        {
          statuses[0]?.code == 'failed' && hasRole('admin') && <Button
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: "#5261A0",
              textTransform: "capitalize",
              fontSize: 14,
              "&:hover": { backgroundColor: "#3e4d7a" },
            }}
            onClick={handleChangePaymentAccount}
          >
            Update Payment Account
          </Button>
        }
      </div>

      <h3 className="text-md mb-4 text-base-blue font-medium">Last updates:</h3>

      <RoundedBox className='bg-white p-8'>
        <ul className="space-y-3">
          {statuses?.slice(0, statusToShow).map((status: FilingStatus, index: number) => (
            <li key={index} className="flex items-center">
              {/* Using inline style for the colored dot since we're using hex colors */}
              <span 
                className={bgColorByStatus(status?.short_name) + " h-4 w-4 rounded-full mr-4"}
                // style={{ backgroundColor: update.color }}
              />
              <p className="text-sm">
                <span className="font-bold">{status?.name}</span>, at {status?.pivot.created_at && dateHour(new Date(status?.pivot.created_at))}
              </p>
            </li>
          ))}
          {
            statusToShow == initialStatusesLength && statuses?.length > initialStatusesLength && <li>...</li>
          }
        </ul>
      </RoundedBox>

      {
        statuses?.length > initialStatusesLength && <div className="flex justify-center mt-4">
          <Button 
            sx={{
              backgroundColor: "#E5EBF8",
              color: "#5261A0",
              textTransform: "capitalize",
              fontSize: 14,
              paddingX: 8,
              borderRadius: "100px",
            }}
            onClick={handleViewAllUpdates}
          >
            View All Updates
          </Button>
        </div>
      }
    </div>
  );
};

export default CaseUpdates;
