import { ReactElement, useMemo, useState } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import {
  FormControlLabel,
  Checkbox,
  Collapse,
  Typography,
} from "@mui/material";
import useUsers from "../../hooks/useUsers";
import { useMessageBoard } from "../assets/MessageBoard";
import FormLabel from "./FormLabel";
import FormInput from "./FormInput";
import { FormButton } from "./FormButton";

interface CreateUserFormProps {
  onSuccess: () => void;
}

const FormCreateUser = ({ onSuccess }: CreateUserFormProps): ReactElement => {
  const { createUser, error } = useUsers();
  const { addMessageSuccess, addMessageError } = useMessageBoard();
  const [isAttorney, setIsAttorney] = useState(false);

  const initialValues = useMemo(() => ({
    email: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    bar_number: '',
  }), []);

const validationSchema = useMemo(() => yup.object().shape({
  email: yup.string().required("Please enter an email").email("Please enter a valid email"),
  first_name: yup.string().required("Please enter your first name"),
  last_name: yup.string().required("Please enter your last name"),
  }), []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const dataToSend: any = {
          email: values.email,
          first_name: values.first_name,
          middle_name: values.middle_name,
          last_name: values.last_name,
        };

        if (isAttorney) {
          dataToSend.bar_number = values.bar_number;
        }

        await createUser(dataToSend);

        addMessageSuccess('The user was created');
        onSuccess();
      } catch (err: any) {
        addMessageError(err.message || "Error creating user:");
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleCheckboxChange = () => {
    setIsAttorney((prev) => {
      if (prev) {
        formik.setFieldValue('bar_number', 0);
      }
      return !prev;
    });
  };

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col space-y-4 w-1/2">
      <FormLabel mandatory>Email</FormLabel>
      <FormInput
        formik={formik}
        name="email"
        type="email"
      />
      <FormLabel mandatory>First Name</FormLabel>
      <FormInput
        formik={formik}
        name="first_name"
      />
      <FormLabel>Middle Name</FormLabel>
      <FormInput
        formik={formik}
        name="middle_name"
      />
      <FormLabel mandatory>Last Name</FormLabel>
      <FormInput
        formik={formik}
        name="last_name"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={isAttorney}
            onChange={handleCheckboxChange}
          />
        }
        label={<Typography sx={{ fontWeight: "bold" }}>As Attorney</Typography>}
      />
      <Collapse in={isAttorney}>
        <div className="flex flex-col space-y-4">
          <FormLabel>Bar Number</FormLabel>
          <FormInput
            formik={formik}
            name="bar_number"
          />
        </div>
      </Collapse>

      <FormButton type="submit" className="m-auto w-3/5 inline-block">Create User</FormButton>
    </form>
  );
};

export default FormCreateUser;
