import { Tabs, Typography } from "@mui/material";
import { ReactElement, useState } from "react";
import PrivateLayout from "../../components/layout/PrivateLayout";
import RoundedBox from "../../components/layout/RoundedBox";
import UsersTable from "./components/UsersTable";
import { StyledTab } from "../my-filings/MyFilings";
import AttorneysTable from "./components/AttorneysTable";

const AdminDashboard = (): ReactElement => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  // Function to render the content for the selected tab
  const renderTabContent = () => {
    switch (selectedTab) {
      case 0:
        return <UsersTable />;
      case 1:
        return <AttorneysTable />;
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };
  return <PrivateLayout>
    <RoundedBox color="normal">
      <RoundedBox className="bg-white py-16 px-8">
        <RoundedBox className="p-4 border-2 border-base-blue rounded-md mb-8">
          <Typography className="text-black">
            Manage users associated with your firm. Revoke/Invoke admin
            privileges, unlock account, re-send activation mails and view the
            user info. Create new and edit current users to make them service
            contacts.
          </Typography>
          <Typography className="text-red-600">
            Note: Remember that users with an active Bar Number will be
            automatically created as a service contact.
          </Typography>
        </RoundedBox>
        <Tabs
          value={selectedTab}
          onChange={handleTabChange}
          centered
          variant="fullWidth"
          className="bg-[#F0F2F5] p-2 rounded-2xl mb-10"
          TabIndicatorProps={{
            style: {
              display: "none", // Hide the bottom indicator
            },
          }}
        >
          <StyledTab label="Firm Users" />
          <StyledTab label="Attorneys" />
        </Tabs>

        {/* Render the content using the abstracted function */}
        <div className="tab-content">{renderTabContent()}</div>
      </RoundedBox>
    </RoundedBox>
  </PrivateLayout>
};

export default AdminDashboard;
