import { ReactElement, useEffect, useState } from "react";
import Dialog from "./Dialog";

const DialogDelete = ({ children, confirm, cancel, ...props }: any): ReactElement => {

  const [localOpen, setLocalOpen] = useState(true);
  const [buttons, setButtons]: [{text: string, action: Function, sx: any}[], any] = useState([]);

  useEffect(() => {
    const buttons: any = [{
      text: 'Cancel',
      action: (e:any) => {
        if(cancel) {
          cancel()
        }
        setLocalOpen(false)
      }
    }];
    if(confirm) {
      buttons.push({
        text: 'Delete',
        action: confirm,
        sx: { color: '#d22' }
      })
    }
    setButtons(buttons);
  }, [confirm]);

  return <Dialog {...{
    ...props,
    buttons
  }}>
    {children}
  </Dialog>
}
export default DialogDelete;