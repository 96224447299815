import React, { useState, useEffect } from "react";
import { Edit, Close } from "@mui/icons-material";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { UserFilters } from "../../../hooks/useUsers";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Box, Tooltip } from "@mui/material";
import useAttorneys from "../../../hooks/useAttorneys";
import FilterAttorneys from "./filters/FilterAttorneys";
import AgTable, { PaginationDataRequest } from "../../../components/table/AgTable";
import DialogCloseable from "../../../components/dialogs/DialogCloseable";
import AttorneyForm from "../../attorneys/AttorneyForm";
import { useMessageBoard } from "../../../components/assets/MessageBoard";

const AttorneysTable: React.FC = () => {
  const { fetchAttorneys, pagination, syncAttorneys, deleteAttorney } = useAttorneys();
  const { addMessageSuccess, addMessageError } = useMessageBoard();

  const [open, setOpen] = useState(false);
  const [selectedDeleteAttorney, setSelectedDeleteAttorney] = useState<any | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  const [showEditAttorney, setShowEditAttorney]: [any|false, Function] = useState(false)

  useEffect(() => {
    fetchAttorneys();
  }, []);

  const onSubmitAttorneys = () => {
    fetchAttorneys()
    setShowEditAttorney(false)
  }

  const handleDeleteAttorney = (attorney: any) => {
    setSelectedDeleteAttorney(attorney);
    setDeleteError(null)
    setOpen(true);
  };

  const handleConfirmDelete = async () => {
    if(!selectedDeleteAttorney) {
      return;
    }
    try {
      await deleteAttorney(selectedDeleteAttorney.id);
      fetchAttorneys();
      addMessageSuccess('The attorney was deleted');
    } catch(e: any) {
      addMessageError(e.message || 'The attorney cannot be deleted');
    }
    setOpen(false);
    setSelectedDeleteAttorney(null);
  };

  const handleCancelDelete = () => {
    setOpen(false);
    setSelectedDeleteAttorney(null);
    setDeleteError(null);
  };

  // // Process user data
  // const processedRows = processAttorneyData(attorneys);
  const actionsCellRender = ({ data, ...props }: { data: any }) => {
    const actions = [];
    actions.push({
      label: "Edit User",
      onClick: setShowEditAttorney,
      icon: <Edit className="text-[#5261A0]" />,
    });
    actions.push({
      label: "Delete User",
      onClick: handleDeleteAttorney,
      icon: <GridDeleteIcon className="text-[#5261A0]" />,
    });
    return actions.map((action: any, index: number) => (
      <Tooltip key={index} title={action.label} arrow className="mr-4">
        <button onClick={() => action.onClick(data)}>
          {action.icon}
        </button>
      </Tooltip>
    ))
  }

  const colDef = [
    { field: "name", headerName: "Name", width: 250 },
    { field: "bar_number", headerName: "Bar Number", width: 150 },
    { field: "actions", headerName: "Actions", width: 50, cellRenderer: actionsCellRender },
  ];

  const handleSyncAttorneys = async () => {
    try {
      await syncAttorneys();
      addMessageSuccess('The attorneys where sync');
    } catch(e: any) {
      addMessageError(e.message || 'The attorneys cannot be sync');
    }
  };
    
  const onPaginationChange = (options: PaginationDataRequest) => {
    return fetchAttorneys({ ...pagination, ...options });
  }

  const onFilterChange = (filters: UserFilters) => {
    return fetchAttorneys({ ...pagination, ...filters, currentPage: 1 });
  }

  return <div>
    {/* Filters for user search and filtering */}
    <FilterAttorneys onChange={onFilterChange} onSyncAttorneys={handleSyncAttorneys} onCreateAttorney={() => setShowEditAttorney(true)}/>

    <AgTable
      data={pagination.data}
      colDef={colDef}
      pagination={pagination}
      fetchMethod={onPaginationChange}
    />

    {/* Delete confirmation dialog */}
    <Dialog open={open} onClose={handleCancelDelete}>
      <DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCancelDelete}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className="mt-10">
        <DialogContentText>
          Are you sure you want to <b>delete</b> the attorney?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        className="mb-8"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Box className="flex justify-center mb-2">
          <Button
            onClick={handleCancelDelete}
            sx={{
              backgroundColor: "#5261A0",
              color: "white",
              paddingX: "32px",
              borderRadius: "32px",
              marginRight: "16px",
              "&:hover": {
                backgroundColor: "#405080",
              },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            disabled={Boolean(deleteError)}
            sx={{
              backgroundColor: deleteError ? "#808080" : "#F23131",
              color: "white",
              paddingX: "32px",
              borderRadius: "32px",
              "&:hover": {
                backgroundColor: deleteError ? "#808080" : "#D92C2C",
              },
            }}
          >
            Delete
          </Button>
        </Box>
        <DialogContentText color="error">{deleteError}</DialogContentText>
      </DialogActions>
    </Dialog>
    <DialogCloseable
      open={!!showEditAttorney}
      onClose={() => setShowEditAttorney(false)}
      title="Edit Attorney"
    >
      <AttorneyForm onSubmitSuccess={() => onSubmitAttorneys()} attorney={showEditAttorney}/>
    </DialogCloseable>
  </div>
};

export default AttorneysTable;
