import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import RoundedBox from '../../../components/layout/RoundedBox';
import BackArrow from '../../../components/BackArrow';
import PrivateLayout from '../../../components/layout/PrivateLayout';
import CaseUpdates from '../../../components/filings/details/CaseUpdates';
import FilingInformation, { FilingInfo } from '../../../components/filings/details/FilingInformation';
import useFilings from '../../../hooks/useFilings';
import dayjs from 'dayjs';

interface CaseDetailsProps {
  caseNumber: string;
  acceptedAt: string;
  updates: { status: string; time: string }[];
  location: string;
  court: string;
  envelopeDraft: string;
  filingType: string;
  submissionTime: string;
  description: string;
}
interface StatusHistory {
  status: string;
  shortName: string;
  date: string;
  time: string;
  color: string;
}

const CaseDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { loading, fetchFilingById } = useFilings();
  // const [filingInfo, setFilingInfo] = useState<FilingInfo>();  //#TODO: create a type equal to what api returs
  const [filingInfo, setFilingInfo] = useState<any>();
  const [statusHistory, setStatusHistory] = useState<StatusHistory[]>();
  useEffect(() => {
    if (!id) {
      return;
    }
    const fetchFiling = async () => {
      const result = await fetchFilingById(id);
      if (!result) {
        return;
      }
      setFilingInfo(result);
    }
    fetchFiling();
  }, [id, fetchFilingById]);

  const handleGoBack = () => {
    navigate('/my-filings')
  }

  if(!filingInfo) {
    return <></>
  }

  return (
    <PrivateLayout>
        <RoundedBox className="bg-white py-6 mb-4">
          <BackArrow onClick={handleGoBack}>
            <span className="tracking-wider">Back</span>
          </BackArrow>
        </RoundedBox>
        <CaseUpdates
          caseNumber={filingInfo.filing_case.docket_id}
          statuses={filingInfo.filing_case.filing_statuses}
        />
        <FilingInformation filingInfo={filingInfo} />
    </PrivateLayout>
  );
};

export default CaseDetails;
