import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import RoundedBox from '../../../components/layout/RoundedBox';
import BackArrow from '../../../components/BackArrow';
import PrivateLayout from '../../../components/layout/PrivateLayout';
import EServiceInformation from '../../../components/services/details/EServiceInformation';
import useFilings from '../../../hooks/useFilings';
import CaseUpdates from '../../../components/filings/details/CaseUpdates';

interface EServiceDetailsProps {
  serviceNumber: string;
  completedAt: string;
  updates: { status: string; time: string }[];
  recipient: string;
  court: string;
  serviceId: string;
  serviceType: string;
  submissionTime: string;
  description: string;
}

const EServiceDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { fetchFilingById } = useFilings();
  const [filingInfo, setFilingInfo] = useState<any>();
  const [serviceDetails, setServiceDetails] = useState<EServiceDetailsProps | null>(null);
  const location = useLocation();

  const handleGoBack = () => {
    const isReceived = location.pathname.includes('/received-service/');
    const isSent = location.pathname.includes('/sent-service/');
    const tab = isReceived ? 'received' : isSent ? 'sent' : 'draft';
    navigate(`/my-e-services?tab=${tab}`);
  };

  useEffect(() => {
    // // Mock data to simulate fetched service details
    // const mockData: EServiceDetailsProps = {
    //   serviceNumber: '12345',
    //   completedAt: '2024-10-10 14:35',
    //   updates: [
    //     { status: 'Pending', time: '2024-10-08 09:00' },
    //     { status: 'InProgress', time: '2024-10-09 11:15' },
    //     { status: 'Completed', time: '2024-10-10 14:35' },
    //   ],
    //   recipient: 'John Doe Law Office',
    //   court: 'Supreme Court | Civil Calendar',
    //   serviceId: '98765',
    //   serviceType: 'Electronic',
    //   submissionTime: '2024-10-08 08:45',
    //   description: 'Service of Process for civil case documents',
    // };
    // // Simulate an API call with a delay
    // setServiceDetails(mockData);
    if (!id) {
      return;
    }
    const fetchFiling = async () => {
      const result = await fetchFilingById(id);
      if (!result) {
        return;
      }
      setFilingInfo(result);
    }
    fetchFiling();

  }, [id]);

  if (!filingInfo) {
    return <></>
  }

  return (
    <PrivateLayout>
      <RoundedBox className="bg-white px-4 py-6 mb-4">
        <BackArrow onClick={handleGoBack}>
          <span className="tracking-wider">Back</span>
        </BackArrow>
      </RoundedBox>
      <CaseUpdates
        caseNumber={filingInfo.filing_case.docket_id}
        statuses={filingInfo.filing_case.filing_statuses}
      />
      <EServiceInformation filingInfo={filingInfo} />
    </PrivateLayout>
  );
};

export default EServiceDetails;
