import { useState, useCallback } from "react";
import { useAuth } from "./auth";
import { HttpPaginationDataResponse, PaginationDataResponse, paginationEmpty, parseResponse } from "../components/table/AgTable";

// Updated User interface
export interface User {
  id: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  status?: string;
  is_service_contact?: number;
  barNumber?: string;
  role?: string;
  is_firm_admin?: number;
}

export interface UserFilters extends PaginationDataResponse {
  role?: string;
  type?: string;
  search?: string;
} 

const useUsers = () => {
  const { axios, getUserData } = useAuth({ middleware: 'auth' });
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState<UserFilters>(paginationEmpty);

  // Fetch users with optional filters
  const fetchUsers = useCallback(async (options?: UserFilters) => {
    setLoading(true);
    setError(null);

    try {
      const params: Record<string, any> = {
        ...options,
        // sort_by: options?.sortBy || "id_desc",
        items_per_page: options?.perPage || 5,
        page: options?.currentPage || 1,
      };
      delete params.currentPage

      const response: HttpPaginationDataResponse = (await axios.get(`/users`, { params })).data;
      setUsers(response.data);
      setPagination({...pagination, ...options, ...parseResponse(response)});
      
      setLoading(false);
    } catch (err: any) {
      const message = err.response?.data?.message || "Failed to fetch users";
      setError(message);
      setLoading(false);
    }
  }, [axios]);

  // Fetch user by email
  const fetchUserByEmail = async (email: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(`/users`, { params: { email } }); // Adjust the API as per your requirement
      const user = response.data.data.find((user: User) => user.email === email);
      setLoading(false);
      return user;
    } catch (err: any) {
      setError("Failed to fetch user by email");
      setLoading(false);
      return null;
    }
  };

  const createUser = async (params: {
    email: string;
    first_name: string;
    middle_name?: string;
    last_name: string;
    barNumber?: number;
  }) => {
    await axios.post(`/users`, params);
    fetchUsers();
  };

  const resendActivationEmail = async (id: number) => {
    const response = await axios.put(`/users/${id}/resend-activation-email`);
  };

  const editUser = async (id: number, updatedUser: Partial<User>) => {
    const response = await axios.put(`/users/${id}`, updatedUser);
    fetchUsers();
  };

  const updateFirmAdminStatus = async (userId: number, isFirmAdmin: number) => {
    try {
      const response = await axios.put(`/users/${userId}?is_firm_admin=${isFirmAdmin}`);
      console.log({ response });
      return response.data.success;
    } catch (error) {
      console.error("Error updating firm admin status:", error);
    }
  };

  const deleteUser = async (id: number) => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`/users`);
        const allUsers = response.data.data;
        const adminUsers = allUsers.filter((user: User) => user.roles.includes("admin"));
        const userToDelete = allUsers.find((user: User) => user.id === id);

        if (!userToDelete) {
          setLoading(false);
          return { success: false, message: "User not found." };
        }

        const isDeletingAdmin = userToDelete.roles.includes("admin");

        if (isDeletingAdmin && adminUsers.length <= 1) {
          setLoading(false);
          return { success: false, message: "Cannot delete the last admin user." };
        }

        await axios.delete(`/users/${id}`);
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== id));

        setLoading(false);
        return { success: true };
      } catch (err: any) {
        const message = err.response?.data?.message || err.message || "Failed to delete user";
        setLoading(false);
        return { success: false, message };
      }
  };

  const getEmailPreferences = async (userId: number) => {
    try {
      const response = await axios.get(`/users/${userId}/email_preferences`);
      const preferences = response.data;

      // Convert numeric values (0 or 1) to boolean using Boolean()
      return {
        // MyFileRunner Emails
        accepted: Boolean(preferences.accepted),
        rejected: Boolean(preferences.rejected),
        submitted: Boolean(preferences.submitted),
        
        serviceundeliverable: Boolean(preferences.serviceundeliverable),
        submissionfailed: Boolean(preferences.submissionfailed),
        receipted: Boolean(preferences.receipted),
        returnforcorrection: Boolean(preferences.returnforcorrection),
        accountlocked: Boolean(preferences.accountlocked),
        submittedbulksummary: Boolean(preferences.submittedbulksummary),
      };
    } catch (error) {
      console.error("Error getting email preferences:", error);
      return {
        submitted: false,
        accepted: false,
        rejected: false,
      }; // Return default values in case of an error
    }
  };

  const updateEmailPreferences = async (userId: number, preferences: {
    submitted: boolean,
    accepted: boolean,
    rejected: boolean,
  }) => {
    try {
      // Convert preferences to query parameters
      const params = new URLSearchParams({
        accepted: preferences.accepted ? "1" : "0",
        rejected: preferences.rejected ? "1" : "0",
        submitted: preferences.submitted ? "1" : "0",
      }).toString();

      // Send POST request to the endpoint
      const response = await axios.post(`/users/${userId}/email_preferences?${params}`);
      
      if (response.status === 200) {
        console.log("Preferences saved successfully!");
      }
    } catch (error) {
      console.error("Error saving preferences:", error);
    }
  };
  
  const syncUsers = async () => {
    const profile= getUserData();
    if(!profile) {
      return;
    }
    if (!profile.firm.id) {
      console.error("Firm not found.");
      return;
    }
    await axios.get(`/firm/${profile.firm.id}/syncUsers`);
    fetchUsers(pagination);
  }

  return {
    users,
    loading,
    error,
    createUser,
    editUser,
    deleteUser,
    fetchUserByEmail,
    fetchUsers,
    pagination,
    updateEmailPreferences,
    getEmailPreferences,
    updateFirmAdminStatus,
    syncUsers,
    resendActivationEmail
  };
};

export default useUsers;
