import { Box, Tab, Tabs } from "@mui/material";
import { ReactElement, useState } from "react";
import { styled } from "@mui/system"; // Use MUI's styled function
import PrivateLayout from "../../components/layout/PrivateLayout";
import RoundedBox from "../../components/layout/RoundedBox";
import MyFilingsTab from "./components/MyFilingsTab";
import DraftFilingsTab from "./components/DraftFilingsTab";
import FirmFilingsTab from "./components/FirmFilingsTab";
import { Link, useSearchParams } from "react-router-dom";
import { useAuth } from "../../hooks/auth";

// Styled Tab component
export const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 600,
  textTransform: "capitalize",
  borderRadius: "8px",
  transition: "color 0.3s, background-color 0.3s, box-shadow 0.3s",
  "&.Mui-selected": {
    color: "#5261A0",
    backgroundColor: "white",
    boxShadow: "0px 5px 20px 0px #0000000D",
  },
  "&:not(.Mui-selected)": {
    color: "#7C7B84",
  },
}));

type TabValue = 'my-filings' | 'firm-filings' | 'draft';

const MyFilingsScreen = (): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentTab = (searchParams.get("tab") as TabValue) || 'my-filings';
  const tabValues: TabValue[] = ['my-filings', 'firm-filings', 'draft'];

  const {hasRole} = useAuth({middleware: 'auth'});

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSearchParams({ tab: tabValues[newValue] });
  };

  const getTabIndex = (value: TabValue): number => {
    return tabValues.indexOf(value);
  };

  // Function to render the content for the selected tab
  const renderTabContent = () => {
    switch (currentTab) {
      case 'my-filings':
      default:
        return <MyFilingsTab />;
      case 'firm-filings':
        return <FirmFilingsTab />;
      case 'draft':
        return <DraftFilingsTab />;
    }
  };

  return <PrivateLayout>
    <RoundedBox color="normal">
      <RoundedBox className="bg-white p-8">
        <Tabs
          value={getTabIndex(currentTab)}
          onChange={handleTabChange}
          centered
          variant="fullWidth"
          className="bg-[#F0F2F5] p-2 rounded-2xl mb-10"
          TabIndicatorProps={{
            style: {
              display: "none", // Hide the bottom indicator
            },
          }}
        >
          <StyledTab label="My Filings" />
          { 
            hasRole('admin') && <StyledTab label="Firm Filings" />
          }
          <StyledTab label="Draft Filings" />
        </Tabs>

        {/* Render the content using the abstracted function */}
        <div className="tab-content">{renderTabContent()}</div>
        <Box className="flex flex-col w-full">
          <Link
            to="/file-case"
            className="bg-[#E7ECF8] w-[405px] text-[#5261A0] rounded-full text-center mx-auto text-base py-2 px-5 hover:bg-[#D0D8EE]"
          >
            Submit New Filing
          </Link>
          <span className="m-auto text-base text-[#5261A0] mt-4">
            No time to File?
            <Link to="/contact-us" className="text-[#5261A0] hover:underline ml-1">
              We can do it for you.
            </Link>
          </span>
        </Box>
      </RoundedBox>
    </RoundedBox>
  </PrivateLayout>
};

export default MyFilingsScreen;
