import * as MUIDialog from "@mui/material";
import { ReactElement, useEffect, useState } from "react";

const DialogCloseable = ({ children, title, subtitle = null, onClose = () => {}, open, width = '500px', ...props }: any): ReactElement => {

  return <MUIDialog.Dialog open={open} {...{PaperProps: { sx: { borderRadius: "10px", maxWidth: 'none', width} }, ...props }} >
    <div className="p-12 relative">
      <div
        className="rounded-full bg-slate-500 w-6 h-6 text-center bold text-white hover:text-black cursor-pointer absolute"
        style={{fontSize: '22px', lineHeight: '20px', top: '20px', right: '20px', width: '22px', height: '22px'}}
        onClick={onClose}
      >
        &times;
      </div>
      {
        title && <div className="mb-2 text-4xl text-base-blue">
          {title}
        </div>
      }
      {
        subtitle && <div className="mb-8 text-md text-base-gray">
          {subtitle}
        </div>
      }
      <div>
        {children}
      </div>
    </div>
  </MUIDialog.Dialog>
}
export default DialogCloseable;