import { Box, Tab, Tabs } from "@mui/material";
import { ReactElement, useState } from "react";
import { styled } from "@mui/system";
import { Link, useSearchParams } from "react-router-dom";
import PrivateLayout from "../../components/layout/PrivateLayout";
import RoundedBox from "../../components/layout/RoundedBox";
import ReceivedEServicesTab from "./components/ReceivedEServicesTab";
import SentEServicesTab from "./components/SentEServicesTab";
import DraftEServicesTab from "./components/DraftEServicesTab";
import { SearchCaseDialogEService } from "../cases/SearchCaseDialog";
import { useAuth } from "../../hooks/auth";

const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: 600,
  textTransform: "capitalize",
  borderRadius: "8px",
  transition: "color 0.3s, background-color 0.3s, box-shadow 0.3s",
  "&.Mui-selected": {
    color: "#5261A0",
    backgroundColor: "white",
    boxShadow: "0px 5px 20px 0px #0000000D",
  },
  "&:not(.Mui-selected)": {
    color: "#7C7B84",
  },
}));

type TabValue = 'received' | 'sent' | 'draft';

const MyEServicesScreen = (): ReactElement => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSearchCaseDialog, setShowSearchCaseDialog]: [any, Function] = useState(false);
  const currentTab = (searchParams.get("tab") as TabValue) || 'received';
  const tabValues: TabValue[] = ['received', 'sent', 'draft'];

  const {hasRole} = useAuth({middleware: 'auth'});

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSearchParams({ tab: tabValues[newValue] });
  };

  const getTabIndex = (value: TabValue): number => {
    return tabValues.indexOf(value);
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case 'received':
        return <ReceivedEServicesTab />;
      case 'sent':
        return <SentEServicesTab />;
      case 'draft':
        return <DraftEServicesTab />;
      default:
        return <ReceivedEServicesTab />;
    }
  };

  return <PrivateLayout>
    <RoundedBox color="normal">
      <RoundedBox className="bg-white p-8">
        <Tabs
          value={getTabIndex(currentTab)}
          onChange={handleTabChange}
          centered
          variant="fullWidth"
          className="bg-[#F0F2F5] p-2 rounded-2xl mb-10"
          TabIndicatorProps={{
            style: { display: "none" },
          }}
        >
          <StyledTab label="Received E-Services" />
          { 
            hasRole('admin') && <StyledTab label="Sent E-Services" />
          }
          <StyledTab label="Draft E-Services" />
        </Tabs>
        <div className="tab-content">{renderTabContent()}</div>
        <Box className="flex flex-col w-full mt-4">
          <a
            onClick={() => setShowSearchCaseDialog(true)}
            className="cursor-pointer bg-[#E7ECF8] w-[405px] text-base-blue rounded-full text-center mx-auto text-base py-2 px-5 hover:bg-[#D0D8EE]"
          >
            Submit New E-Service
          </a>
        </Box>
      </RoundedBox>
    </RoundedBox>
    <SearchCaseDialogEService
      open={showSearchCaseDialog}
      onClose={() => setShowSearchCaseDialog(false)}
    />
  </PrivateLayout>
};

export default MyEServicesScreen;
