import { useAuth } from "./auth";
import { useSelector } from "react-redux";
import { selectAttorneys, setAttorneys } from "../components/assets/CaseWizard";
import { useCallback, useState } from "react";
import { PaginationDataResponse, paginationEmpty } from "../components/table/AgTable";

export interface AttorneyFilters extends PaginationDataResponse {
  search?: string;
};

const useAttorneys = () => {
  const { axios, getUserData } = useAuth({ middleware: 'auth' });
  const attorneys = useSelector(selectAttorneys);
  const [pagination, setPagination] = useState<AttorneyFilters>(paginationEmpty);

  const createAttorney = async (params: {
    first_name: string;
    middle_name?: string;
    last_name: string;
    bar_number: string;
  }) => {
    await axios.post(`/attorneys`, params);
    fetchAttorneys(pagination);
  };

  const editAttorney = async (id: number, params: {
    id: number;
    first_name: string;
    middle_name?: string;
    last_name: string;
    bar_number: string;
  }) => {
    const response = await axios.put(`/attorneys/${id}`, params);
    fetchAttorneys(pagination);
  };

  const deleteAttorney = async (id: number) => {
    const response = await axios.delete(`/attorneys/${id}`);
    fetchAttorneys(pagination);
  };

  const fetchAttorneysList = useCallback(async () => {
    const options: any = {currentPage: 1, perPage: 1000};
    fetchAttorneys(options)
  }, []);

  const fetchAttorneys = useCallback(async (options?: AttorneyFilters) => {
    try {
      const params: Record<string, any> = {
        ...options,
        // sort_by: options?.sortBy || "id_desc",
        items_per_page: options?.perPage || 5,
        page: options?.currentPage || 1,
      };
      delete params.currentPage
      const response = (await axios.get(`/attorneys`, { params })).data;

      setPagination(response);
      setAttorneys(response.data);
    } catch (err: any) {
      const message = err.response?.data?.message || "Failed to fetch attorneys";
    }
  }, [axios]);

  const syncAttorneys = async () => {
    const profile = getUserData();
    if (!profile) {
      return;
    }
    if (!profile.firm.id) {
      console.error("Firm not found.");
      return;
    }
    await axios.get(`/firm/${profile.firm.id}/syncAttorneys`);
    fetchAttorneys(pagination);
  }

  return {
    attorneys,
    pagination,
    createAttorney,
    editAttorney,
    fetchAttorneys,
    fetchAttorneysList,
    syncAttorneys,
    deleteAttorney
  };
};

export default useAttorneys;
