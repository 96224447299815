import { ArrowDropDown, CalendarMonth, Height } from "@mui/icons-material";
import { Button, Popover, styled, TextField } from "@mui/material";
import { DateCalendar, DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import quarterOfYear from 'dayjs/plugin/quarterOfYear'; 
import { ReactElement, useEffect, useState } from "react";
import { FormButton } from "../forms/FormButton";

dayjs.extend(quarterOfYear);

export interface DateRangeInputProps {
  value: DateRange
  onChange?: Function
}

export interface DateRange {
  dateFrom?: Dayjs | null
  dateTo?: Dayjs | null
}

const emptyRange: DateRange = {
  dateFrom: null,
  dateTo: null
}

const areDifferent = (range1: DateRange, range2: DateRange) => {
  return `${range1.dateFrom}-${range1.dateTo}` != `${range2.dateFrom}-${range2.dateTo}`;
}

const renderRange = (range: DateRange) => {
  let str = '';
  if (range.dateFrom || range.dateTo) {
    str += range.dateFrom ? range.dateFrom.format('MM/DD/YYYY') : '...';
    str += ' - '
    str += range.dateTo ? range.dateTo.format('MM/DD/YYYY') : '...';
  }
  return str;
}

const MfrDateCalendar = styled(DateCalendar)(({ theme }) => ({
  '.MuiPickersDay-root': {
    padding: '0', // Reduce el padding de cada día
    margin: '0',   // Eliminar márgenes si es necesario
  },
  '.MuiPickersCalendarHeader-root': {
    marginBottom: '0', // Reduce el margen del encabezado
  },
  // '.MuiPickersCalendarHeader-label': {
  //   fontSize: '1rem', // Mantiene el tamaño del texto
  // },
  // '.MuiTypography-root': {
  //   fontSize: '0.875rem', // Ajusta el tamaño del texto de los días
  // },
}));

const defaultText = 'Showing all time data.';

export const DateRangeInput = ({ value = emptyRange, onChange, ...props }: DateRangeInputProps): ReactElement => {

  const [dateRangeOriginal, setDateRangeOriginal] = useState<DateRange>({ ...emptyRange });
  const [dateRangeNew, setDateRangeNew] = useState<DateRange>({ ...emptyRange });

  const [text, setText] = useState<string>(defaultText);
  const [textInput, setTextInput] = useState<string>(defaultText);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setTextInput(text || renderRange(dateRangeNew));
    if (areDifferent(dateRangeOriginal, dateRangeNew)) {
      onChange && onChange(dateRangeNew);
      setDateRangeOriginal(dateRangeNew);
    }
  };

  const handleClearFilters = async () => {
    // setAnchorEl(null);
    setDateRangeNew({ ...emptyRange });
    setDateRangeOriginal({ ...emptyRange });
    setTextInput(defaultText);
    if (areDifferent(dateRangeOriginal, emptyRange)) {
      onChange && onChange({ ...emptyRange });
    }
    setText(defaultText)
  }

  const setLastMonth = () => {
    const dateFrom: Dayjs = dayjs().subtract(1, 'month').startOf('month');
    const dateTo: Dayjs = dayjs().subtract(1, 'month').endOf('month');
    setDateRangeNew({ dateFrom, dateTo });
    console.log({ dateFrom, dateTo })
    setText('Showing data from the past month.');
  }

  const setLastQuarter = () => {
    const dateFrom = dayjs().subtract(1, 'quarter').startOf('quarter');
    const dateTo = dayjs().subtract(1, 'quarter').endOf('quarter');
    setDateRangeNew({ dateFrom, dateTo })
    setText('Showing data from the past quarter.');
  }

  const setLastYear = () => {
    const dateFrom = dayjs().subtract(1, 'year').startOf('year');
    const dateTo = dayjs().subtract(1, 'year').endOf('year');
    setDateRangeNew({ dateFrom, dateTo })
    setText('Showing data from the past year.');
  }

  const setCustomRange = () => {
    setText('');
  }

  useEffect(() => {
    if (value) {
      return;
    }
    setDateRangeOriginal(value);
    setDateRangeNew(value);
  }, [value]);

  return <>
    <Button
      variant="outlined"
      onClick={handlePopoverOpen}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '50px',
        borderColor: '#EFF0F6',
        color: '#5261A0',
        padding: '6px 16px',
        textTransform: 'none',
        // fontWeight: 'bold',
        width: 360,
      }}
    >
      <div className="flex items-center">
        <CalendarMonth style={{ marginRight: '8px' }} className="flex-initial"/>
        <div className="flex-1 flex relative" style={{top: '2px'}}>
          <b className="flex-initial">
            Date Filter
          </b>
          <span className="flex-1 text-gray-500 ml-2 truncate">
            {textInput}
          </span>
        </div>
      </div>
      <ArrowDropDown />
    </Button>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        borderRadius: '25px'
      }}
    >
      <div className="p-8">
        <div className="flex flex-row justify-between items-center mb-4 rounded-xl">
          <span className="font-bold text-base">Select Date Range:</span>
          <Button
            onClick={handleClearFilters}
            variant="outlined"
            sx={{
              fontSize: '0.75rem',
              borderColor: '#3E4A7F',
              color: '#3E4A7F',
              borderRadius: '50px',
              textTransform: 'none',
              // fontWeight: 'bold',
              // padding: '6px 24px',
              // marginTop: '16px',
            }}
          >
            Clear Filter
          </Button>
        </div>
        <div className="flex gap-4">
          <div className="flex flex-initial">
            <div className="flex flex-col gap-2">
              <FormButton className="px-2" onClick={setLastMonth}>
                Last Month
              </FormButton>
              <FormButton className="px-2" onClick={setLastQuarter}>
                Last Quarter
              </FormButton>
              <FormButton className="px-2" onClick={setLastYear}>
                Last Year
              </FormButton>
              <FormButton className="px-2" onClick={setCustomRange}>
                Custom Range
              </FormButton>
            </div>
          </div>
          {
            !text && <>
              <MfrDateCalendar
                value={dateRangeNew.dateFrom}
                onChange={(newValue) => setDateRangeNew({ ...dateRangeNew, dateFrom: newValue })}
                className="shadow-md"
                />
              <MfrDateCalendar
                value={dateRangeNew.dateTo}
                onChange={(newValue) => setDateRangeNew({ ...dateRangeNew, dateTo: newValue })}
                className="shadow-md"
              />
            </>
          }
          {
            text && <div style={{minWidth: '600px'}} className="flex-1 flex flex-col justify-center">
              <div className="text-center text-blue-900 font-base-blue">
                {text}
              </div>
            </div>
          }
        </div>
        <div className="flex flex-row justify-center mt-4">
          <Button
            onClick={handlePopoverClose}
            variant="contained"
            sx={{
              backgroundColor: '#5261A0',
              color: 'white',
              borderRadius: '50px',
              textTransform: 'none',
              fontWeight: 'bold',
              padding: '6px 24px',
              '&:hover': {
                backgroundColor: '#3E4A7F',
              },
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </Popover>
  </>
}