import { ReactElement, useEffect, useState, useMemo } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Button,
  Grid,
  Box,
  Switch,
} from "@mui/material";
import useUsers from "../../hooks/useUsers";
import { useMessageBoard } from "../assets/MessageBoard";
import { FormButton } from "./FormButton";
import FormInput from "./FormInput";
import FormLabel from "./FormLabel";
import DialogEmailPreferences from "../dialogs/DialogEmailPreferences";

interface EditUserFormProps {
  userEmail: string | null;
  onSuccess: () => void;
}

interface FormValues {
  id: number;
  first_name: string;
  middle_name: string;
  last_name: string;
  is_service_contact: boolean;
}

const FormEditUser = ({ userEmail, onSuccess }: EditUserFormProps): ReactElement => {
  const { fetchUserByEmail, editUser, updateFirmAdminStatus, error } = useUsers();
  const { addMessageSuccess, addMessageError } = useMessageBoard();
  const [formData, setFormData] = useState<FormValues | null>(null);
  const [isFirmAdmin, setIsFirmAdmin] = useState<boolean>(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const fetchUser = async () => {
    try {
      const user = await fetchUserByEmail(userEmail);
      if (user) {
        setFormData({
          id: user.id,
          first_name: user.first_name,
          middle_name: user.middle_name,
          last_name: user.last_name,
          is_service_contact: user.is_service_contact,
        });
        setIsFirmAdmin(user.roles.includes("admin"));
      }
    } catch (err) {
      console.error("Failed to fetch user", err);
    }
  };

  useEffect(() => {
    if (!userEmail) return;
    fetchUser();
  }, [userEmail]);

  const validationSchema = useMemo(() => yup.object().shape({
    first_name: yup.string().required("Please enter the first name"),
    middle_name: yup.string().notRequired(),
    last_name: yup.string().required("Please enter the last name"),
    is_service_contact: yup.boolean(),
  }), []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formData || {
      first_name: '',
      middle_name: '',
      last_name: '',
      is_service_contact: false,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!formData) return;

      try {
        const updatedValues = {
          ...values,
          is_service_contact: values.is_service_contact ? 1 : 0,
        };

        await editUser(formData.id, updatedValues);
        addMessageSuccess('The user was updated');
        onSuccess();
      } catch (err: any) {
        addMessageError(err.mesage || 'The cannot be updated');
        console.error("Failed to edit user", error);
      }
    },
  });

  const handleToggleFirmAdmin = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!formData) return;
    const newIsFirmAdmin = e.target.checked;

    try {
      const success = await updateFirmAdminStatus(formData.id, newIsFirmAdmin ? 1 : 0);
      if (success) {
        setIsFirmAdmin(newIsFirmAdmin);
        addMessageSuccess("Firm admin status updated.");
      }
    } catch (error) {
      console.error("Failed to update firm admin status", error);
    }
  };

  const handleDialogOpen = () => {
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  if (!formData) {
    return <Typography>User not found</Typography>;
  }

  return (
    <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
      <Grid container spacing={4}>
        {/* Left section */}
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column" width="100%" gap={2}>
            <Box>
              <FormLabel>Email</FormLabel>
              <input
                value={userEmail}
                disabled
                className="p-2 w-full border rounded-md tracking-wider hover:border-gray-300 focus:outline-none focus:border-gray-300"
              />
            </Box>
            <Box mb={2}>
              <FormLabel>First Name</FormLabel>
              <FormInput name="first_name" formik={formik} />
            </Box>

            <Box mb={2}>
              <FormLabel>Middle Name</FormLabel>
              <FormInput name="middle_name" formik={formik} />
            </Box>

            <Box mb={2}>
              <FormLabel>Last Name</FormLabel>
              <FormInput name="last_name" formik={formik} />
            </Box>

            <Box mb={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="is_service_contact"
                    checked={formik.values.is_service_contact}
                    onChange={() => formik.setFieldValue('is_service_contact', !formik.values.is_service_contact)}
                  />
                }
                label={<Typography sx={{ fontWeight: "bold" }}>Service Contact</Typography>}
              />
            </Box>

            <Box mt={3}>
              <FormButton type="submit" className="m-auto w-3/5 inline-block">
                Save Changes
              </FormButton>
            </Box>
          </Box>
        </Grid>

        {/* Right section */}
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <Box mb={3}>
              <Button
                onClick={handleDialogOpen}
                variant="outlined"
                sx={{
                  color: "#5261A0",
                  border: "1px solid #5261A0",
                  textTransform: "none",
                  padding: "16px 32px"
                }}
              >
                Configure Email Preferences
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                mt: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold", color: "black" }}>Firm Admin</Typography>
              <Switch
                sx={{
                  color: "#5261A0",
                  "&.Mui-checked": { color: "#5261A0" },
                }}
                checked={isFirmAdmin}
                onChange={handleToggleFirmAdmin}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>

      <DialogEmailPreferences
        open={isDialogOpen}
        onClose={handleDialogClose}
        onSave={onSuccess}
        userId={formData.id}
      />
    </form>
  );
};

export default FormEditUser;
