import { Profile, Token, User } from "../repositories/Models";
import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { useLoader } from "../components/assets/Loader";
import { useMessageBoard } from "../components/assets/MessageBoard";
import { createSlice } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { getCurrentApiUrl } from "../utils/utils";

// slice ----------------------------------

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userData: null,
    userProfile: null,
  },
  reducers: {
    setUserData: (state: any, payload: { payload: Profile }) => {
      state.userData = payload.payload;
    },
    clearUserData: (state: any) => {
      state.userData = null;
      state.userProfile = null;
    },
  },
});

// export const { setUserData } = authSlice.actions

export const selectAuth = (state: any) => state.auth.userData;
export const selectUserProfile = (state: any) => state.auth.userProfile;

export default authSlice.reducer;

// hook ----------------------------------

export const useAuth = (
  {
    middleware,
    displayErrors = true,
  }: { middleware?: "auth"; displayErrors?: boolean } = {displayErrors: true},
) => {
  const { showLoader, hideLoader } = useLoader();
  const { addMessageError, addMessageSuccess } = useMessageBoard();
  const dispatch = useDispatch();

  const user: Profile = useSelector(selectAuth);

  const navigate = useNavigate();
  const location = useLocation();

  const setUserData = (user: Profile) => dispatch(authSlice.actions.setUserData(user));
  const clearUserData = () => dispatch(authSlice.actions.clearUserData());

  let refreshTokenPromise: Promise<any> | null = null;

  // useEffect(() => {
    axios.defaults.baseURL = getCurrentApiUrl();

    axios.interceptors.request.clear();
    axios.interceptors.request.use(async (config) => {
      if (middleware == "auth") {
        let token: Token | null = loadToken();
        if (
          token &&
          shouldRefreshToken(token) &&
          config.url != "/auth/refresh"
        ) {
          token = await refreshToken();
        }
        if (token) {
          config.headers.Authorization = `${token.token_type} ${token.access_token}`;
        }
      }
      showLoader();
      return config;
    });

    axios.interceptors.response.clear();
    axios.interceptors.response.use((response) => {
      hideLoader();
      return response;
    }, (error) => {
      hideLoader();
      if (displayErrors && error?.response?.data?.error?.message) {
        addMessageError(error?.response?.data?.error?.message);
      }
      if (error?.response?.status == 401) {
        if (location.pathname != "/login") {
          navigate("/login");
          return false;
        }
      }
      return Promise.reject(error);
    });
  // }, []);

  const login = async ({ ...data }: { email: string; password: string }) => {
    try {
      const response: AxiosResponse<Token> = await axios.post<Token>(
        "/auth/login",
        data,
      );
      saveToken(response.data);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const logout = async () => {
    await axios
      .post<Token>("/auth/logout")
      .then(() => {})
      .catch(() => {});
    // if logout fails in server, I locally remove data anyway
    clearUserData();
    eraseToken();
    navigate("/login");
  };

  const forgotPassword = ({ ...data }: { email: string }) => {
    return axios.post("/auth/forgot-password", data);
  };

  const resetPassword = async ({
    ...data
  }: {
    password: string;
    password_confirmation: string;
    token: string;
    email: string;
  }) => {
    try {
      const response = await axios.post<Token>("/auth/reset-password", data);
      saveToken(response.data);
      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  const saveToken = (token: Token): any => {
    token.expiration_date = DateTime.now()
      .plus({ seconds: token.expires_in })
      .toISO();
    localStorage.setItem("token", JSON.stringify(token));
  };

  const shouldRefreshToken = (token: Token | null): boolean => {
    if (!token?.expiration_date) {
      return true;
    }
    // I refresh the token 30 minutes before its expiration
    return DateTime.now() > DateTime.fromISO(token.expiration_date).minus({ minutes: 30 });
  }

  const refreshToken = async (): Promise<Token | null> => {
    if (refreshTokenPromise) {
      return refreshTokenPromise;
    }
    refreshTokenPromise = axios
      .post("/auth/refresh")
      .then((response) => {
        saveToken(response.data);
        return loadToken();
      })
      .catch(() => {})
      .finally(() => {
        refreshTokenPromise = null;
      });
    return refreshTokenPromise;
  };

  const loadToken = (): Token | null => {
    let token_str: string | null = localStorage.getItem("token");
    if (!token_str) {
      return null;
    }
    const token: Token = JSON.parse(token_str);
    return token;
  };

  const eraseToken = (): any => {
    localStorage.removeItem("token");
  };

  const refreshUserData = async () => {
    if (!useAuth.fetching) {
      useAuth.fetching = true;
      axios.get<Profile>("/profile")
        .then((response) => {
          setUserData(response.data);
        })
        .catch((error) => {
          console.error("Failed to fetch user data:", error);
        })
        .finally(() => {
          useAuth.fetching = false;
        });
    }
  }

  const getUserData = (): Profile | undefined => {
    if (!user) {
      refreshUserData();
    }
    return user;
  };

  const hasRole = (roles: string|string[]): boolean => {
    const user = getUserData();
    if(!user) {
      return false;
    }
    roles = Array.isArray(roles) ? roles : [roles];
    for(let role of roles) {
      if(user.user.roles.includes(role)) {
        return true;
      }
    }
    return false;
  }

  return {
    login,
    logout,
    forgotPassword,
    resetPassword,
    getUserData,
    hasRole,
    axios,
  };
};

useAuth.fetching = false;
