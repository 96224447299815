import { ReactElement, useEffect, useState } from "react";
import { useFormik } from "formik";
import FormInput from "../../components/forms/FormInput";
import FormLabel from "../../components/forms/FormLabel";
import { FormButton } from "../../components/forms/FormButton";
import { useAuth } from "../../hooks/auth";
import { Case, makeName, objectToQuery, VALIDATION_EMAIL, VALIDATION_EMAIL_MULTIPLE, VALIDATION_PHONE } from "../../utils/utils";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import RoundedBox from "../../components/layout/RoundedBox";
import FormError from "../../components/forms/FormError";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import * as yup from 'yup';
import useConfigs from "../../hooks/useConfigs";
import useServiceContacts from "../../hooks/useServiceContacts";
import { useMessageBoard } from "../../components/assets/MessageBoard";

const ServiceContactForm = ({ onSubmitSuccess = null, fcase }: { onSubmitSuccess?: null | Function, fcase: Case }): ReactElement => {

  const { axios } = useAuth({ middleware: 'auth' });
  const { configRegex, configValidationMessage } = useConfigs();
  const { searchServiceContacts, saveFirmServiceContact, removeServiceContactFromCase, listFirmServiceContacts, addExternalServiceContactToCase, addFirmServiceContactToCase } = useServiceContacts();

  const { addMessageError } = useMessageBoard();
  const [serviceContacts, setServiceContacts]: [any[], Function] = useState([])
  const [searchedServiceContacts, setSearchedServiceContacts] = useState<any>()
  const [row, setRow]: [number, Function] = useState(0)

  useEffect(() => {
    fetchFirmServiceContacts()
  }, []);

  const fetchFirmServiceContacts = async () => {
    setServiceContacts(await listFirmServiceContacts());
  }

  const initialValuesSearch: any = {
    first_name: '',
    last_name: '',
    firm_name: '',
    email: '',
    error: '',
  };

  const formikSearch = useFormik({
    initialValues: initialValuesSearch,
    validate: (data) => {
      if (!data.first_name && !data.last_name && !data.firm_name && !data.email) {
        return {
          error: 'Please enter the search keyword in atleast one of the boxes provided.'
        }
      }
      return {}
    },
    onSubmit: async (values, { setSubmitting }) => {
      delete values.error;
      try {
        setSearchedServiceContacts(undefined)
        const data = await searchServiceContacts(values);
        const caseServiceContactsTylerIds = fcase.service_contacts.map(sc => sc.tyler_service_contact.tyler_service_contact_id)
        setSearchedServiceContacts(data.filter((sc: any) => !caseServiceContactsTylerIds.includes(sc.tyler_service_contact_id)))
      } catch (e) {
        addMessageError(e.message)
      }
    }
  });

  const initialValuesNew: any = {
    first_name: '',
    last_name: '',
    firm_name: '',
    phone_number: '',
    email: '',
    administrative_copy: '',
  };

  const formikNew = useFormik({
    initialValues: initialValuesNew,
    validationSchema: () => {
      return yup.object().shape({
        first_name: yup.string().required('Please enter a first name').matches(configRegex('PartyFirstName'), configValidationMessage('PartyFirstName')),
        last_name: yup.string().required('Please enter a last name').matches(configRegex('PartyLastName'), configValidationMessage('PartyLastName')),
        email: yup.string().required('Please enter an email').matches(VALIDATION_EMAIL, 'Please enter a valid email'),

        middle_name: yup.string().matches(configRegex('PartyMiddleName'), configValidationMessage('PartyMiddleName')),
        phone_number: yup.string().matches(VALIDATION_PHONE, 'Please enter a valid phone number'),
        administrative_copy: yup.string().matches(VALIDATION_EMAIL_MULTIPLE, 'Please enter multiple emails separated by commas'),
      });
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        console.log(values)
        await saveFirmServiceContact(values, fcase);
        onSubmitSuccess && onSubmitSuccess();
      } catch (e: any) {
        addMessageError(e.message)
      }
    }
  });

  const addExternalServiceContact = async (serviceContact: any) => {
    try {
      await addExternalServiceContactToCase(fcase, serviceContact)
    } catch (e) {

    }
    onSubmitSuccess && onSubmitSuccess();
  }

  const actions = (params: GridRenderCellParams) => <div className="actions">
    <AddCircleIcon
      onClick={() => addExternalServiceContact(params.row)}
      className="cursor-pointer hover:opacity-75"
    />
  </div>

  const columns: GridColDef[] = [
    { field: 'fullName', headerName: 'Name', flex: 250, valueGetter: (value, row) => makeName(row) },
    { field: 'email', headerName: 'Email', flex: 200 },
    { field: 'firm_name', headerName: 'Firm', flex: 200 },
    { field: 'id', headerName: 'Action', flex: 50, renderCell: actions, align: 'right', headerAlign: 'right' },
  ];

  // ---------------------------------------------------

  const toggleFirmContact = async (value: boolean, serviceContact: any) => {
    if (value) {
      await addFirmServiceContactToCase(fcase, serviceContact)
    } else {
      await removeServiceContactFromCase(fcase, serviceContact)
    }
    onSubmitSuccess && onSubmitSuccess();
  }

  return <div className="text-sm">
    <div className="flex flex-col gap-6 mb-8">
      <div>
        <div
          className="border-1 border-base-blue w-1/2 py-2 px-4 rounded-md hover:bg-slate-100 cursor-pointer flex text-base-blue"
          onClick={() => row == 0 ? setRow(-1) : setRow(0)}
        >
          <div className="flex-1" style={{ fontWeight: row == 0 ? 'bold' : 'normal' }}>
            Search For Service Contact
          </div>
          <div className="flex-initial text-xs">
            {row == 0 ? <>&#9650;</> : <>&#9660;</>}
          </div>
        </div>
        {
          row == 0 && <RoundedBox className="bg-background-light-blue mt-6">
            <form onSubmit={formikSearch.handleSubmit}>
              <div className="p-4">
                <div className="flex flex-col gap-4">
                  <div className="flex-1 w-2/3">
                    <FormLabel>
                      First Name
                    </FormLabel>
                    <FormInput
                      name="first_name"
                      formik={formikSearch}
                    />
                  </div>
                  <div className="flex-1 w-2/3">
                    <FormLabel>
                      Last Name
                    </FormLabel>
                    <FormInput
                      name="last_name"
                      formik={formikSearch}
                    />
                  </div>
                  <div className="flex-1 w-2/3">
                    <FormLabel>
                      Firm
                    </FormLabel>
                    <FormInput
                      name="firm_name"
                      formik={formikSearch}
                    />
                  </div>
                  <div className="flex-1 w-2/3">
                    <FormLabel>
                      Email
                    </FormLabel>
                    <FormInput
                      name="email"
                      formik={formikSearch}
                    />
                  </div>
                  <div className="flex-1 text-center">
                    <FormError formik={formikSearch} name="error" />
                    <FormButton className="w-2/5 m-auto bg-background-overlay-blue rounded-full mt-6" type="submit">
                      Search For Attorney
                    </FormButton>
                  </div>
                  {
                    searchedServiceContacts && searchedServiceContacts.length > 0 && <div className="bg-white">
                      <DataGrid
                        className="grid-mfr"
                        autoHeight
                        rows={searchedServiceContacts}
                        columns={columns}
                        initialState={{
                          pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                          },
                        }}
                        pageSizeOptions={[5, 10]}
                        rowSelection={false}
                      />
                    </div>
                  }
                  {
                    searchedServiceContacts && searchedServiceContacts.length == 0 && <div className="bg-white p-4 rounded-sm text-center">
                      No rows to show
                    </div>
                  }
                </div>
              </div>
            </form>
          </RoundedBox>
        }
      </div>
      <div>
        <div
          className="border-1 border-base-blue w-1/2 py-2 px-4 rounded-md hover:bg-slate-200 cursor-pointer flex text-base-blue"
          onClick={() => row == 1 ? setRow(-1) : setRow(1)}
        >
          <div className="flex-1" style={{ fontWeight: row == 1 ? 'bold' : 'normal' }}>
            Select From Existing Firm Contracts
          </div>
          <div className="flex-initial text-xs text-base-blue">
            {row == 1 ? <>&#9650;</> : <>&#9660;</>}
          </div>
        </div>
        {
          row == 1 && <RoundedBox className="bg-background-light-blue mt-6">
            <div className="p-4 flex flex-col gap-2">
              {
                serviceContacts.length > 0 && serviceContacts.map((serviceContact: any) => <div className="flex-1" key={`sc-${serviceContact.id}`}>
                  <label>
                    <input
                      type="checkbox"
                      className="mr-2"
                      onChange={(e) => toggleFirmContact(e.target.checked, serviceContact)}
                      style={{ position: 'relative', top: '1px' }}
                      checked={fcase.service_contacts.find((sc: any) => sc.id == serviceContact.id)}
                      value={serviceContact.id}
                    />
                    {makeName(serviceContact)}
                  </label>
                </div>)
              }
            </div>
          </RoundedBox>
        }
      </div>
      <div>
        <div
          className="border-1 border-base-blue w-1/2 py-2 px-4 rounded-md hover:bg-slate-200 cursor-pointer flex text-base-blue"
          onClick={() => row == 2 ? setRow(-1) : setRow(2)}
        >
          <div className="flex-1" style={{ fontWeight: row == 2 ? 'bold' : 'normal' }}>
            Add New Service Contact To Firm
          </div>
          <div className="flex-initial text-xs text-base-blue">
            {row == 2 ? <>&#9650;</> : <>&#9660;</>}
          </div>
        </div>
        {
          row == 2 && <RoundedBox className="bg-background-light-blue mt-6">
            <form onSubmit={formikNew.handleSubmit}>
              <div className="p-4">
                <div className="flex flex-col gap-6">
                  <div className="flex-1 flex gap-6">
                    <div className="flex-1">
                      <FormLabel mandatory>
                        First Name
                      </FormLabel>
                      <FormInput
                        name="first_name"
                        formik={formikNew}
                      />
                    </div>
                    <div className="flex-1">
                      <FormLabel>
                        Middle Name
                      </FormLabel>
                      <FormInput
                        name="middle_name"
                        formik={formikNew}
                      />
                    </div>
                  </div>
                  <div className="flex-1 flex gap-6">
                    <div className="flex-1">
                      <FormLabel mandatory>
                        Last Name
                      </FormLabel>
                      <FormInput
                        name="last_name"
                        formik={formikNew}
                      />
                    </div>
                    <div className="flex-1">
                      <FormLabel>
                        Phone Number
                      </FormLabel>
                      <FormInput
                        name="phone_number"
                        formik={formikNew}
                      />
                    </div>
                  </div>
                  <div className="flex-1 flex gap-6">
                    <div className="flex-1">
                      <FormLabel mandatory>
                        Email
                      </FormLabel>
                      <FormInput
                        name="email"
                        formik={formikNew}
                      />
                    </div>
                    <div className="flex-1">
                      <FormLabel>
                        Administrative Copy
                      </FormLabel>
                      <FormInput
                        name="administrative_copy"
                        formik={formikNew}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex-1 text-center">
                  <FormButton className="w-2/5 m-auto bg-background-overlay-blue rounded-full mt-6" type="submit">
                    Submit
                  </FormButton>
                </div>
              </div>
            </form>
          </RoundedBox>
        }
      </div>
    </div>
    {/* <div className="text-center">
      <FormButton className="m-auto w-1/5 inline-block">
        Submit
      </FormButton>
    </div> */}
  </div>;
};

export default ServiceContactForm;