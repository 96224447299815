import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import CustomTablePagination from "./CustomTablePagination";

interface Action {
  label: string;
  onClick: (id: any) => void;
  icon: React.ReactNode;
}

interface Column {
  field: string;
  headerName: string;
  colorMapping?: { [key: string]: string }; // Optional color mapping for the field values
}

interface CustomTableProps {
  rows: any[];
  columns: Column[];
  loading: boolean;
  variant?: "default" | "primary";
  dataFetcher?: Function
}

export interface FilterOptions {
  search?: string;
  page?: number;
  pageSize?: number;
  sortBy?: string;
}

const getVariantStyles = (variant: "default" | "primary") => {
  switch (variant) {
    case "primary":
      return {
        header: {
          backgroundColor: "#5261A0",
          color: "white",
          fontWeight: "800",
        },
        cell: {
          color: "#7C7B84",
        },
      };
    default:
      return {
        header: {
          color: "#757575",
          fontWeight: "800",
        },
        cell: {
          color: "#7C7B84",
        },
      };
  }
};

const CustomTable: React.FC<CustomTableProps> = ({
  rows = [],
  columns = [],
  loading = false,
  variant = "default",
  dataFetcher
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const styles = getVariantStyles(variant);

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }} elevation={0}>
      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: styles.header.backgroundColor }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.field}
                  sx={{ color: styles.header.color }}
                >
                  {column.headerName}
                </TableCell>
              ))}
              <TableCell sx={{ color: styles.header.color }} className="text-right">
                <div className="text-right">Actions</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : rows.length === 0 ? (
              <TableRow>
                <TableCell colSpan={columns.length + 1} align="center">
                  <Typography variant="body1" color="textSecondary">
                    No data available
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, row_index) => (
                  <TableRow key={row.id ? row.id : row_index}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        sx={{
                          color:
                            column.colorMapping &&
                            column.colorMapping[row[column.field]]
                              ? column.colorMapping[row[column.field]]
                              : styles.cell.color,
                        }}
                      >
                        {row[column.field]}
                      </TableCell>
                    ))}
                    <TableCell sx={{ color: styles.cell.color }}>
                      <div className="space-x-2 text-right">
                        {row.actions.map((action: Action, index: number) => (
                          <Tooltip key={index} title={action.label} arrow>
                            <button onClick={() => action.onClick(row.id)}>
                              {action.icon}
                            </button>
                          </Tooltip>
                        ))}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {
        !dataFetcher && <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
      {
        !dataFetcher && <CustomTablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      }
    </Paper>
  );
};

export default CustomTable;
