import React, { useState, useEffect } from "react";
import { Edit, Close } from "@mui/icons-material";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import useUsers, { UserFilters } from "../../../hooks/useUsers";
import FilterUsers from "./filters/FilterUsers";
import EmailIcon from '@mui/icons-material/Email';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, IconButton, Box, Tooltip } from "@mui/material";
import AgTable, { PaginationDataRequest } from "../../../components/table/AgTable";
import { useMessageBoard } from "../../../components/assets/MessageBoard";

const UsersTable: React.FC = () => {
  const { fetchUsers, deleteUser, syncUsers, pagination, resendActivationEmail } = useUsers();
  const navigate = useNavigate();
  const { addMessageSuccess, addMessageError } = useMessageBoard();

  const [open, setOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [deleteError, setDeleteError] = useState<string | null>(null);
  
  useEffect(() => {
    fetchUsers();
  }, []);

  const handleResendEmail = async (user: any) => {
    try {
      await resendActivationEmail(user.id);
      addMessageSuccess('The activation email was sent succesfully');
    } catch(e: any) {
      addMessageError(e.message || 'The activation email could no be sent');
    }
  }

  const handleEditUser = (user: any) => {
    const { email } = user;
    navigate(`/admin-dashboard/user-management/edit-user/${user.id}`, { state: { email } });
  };

  const handleDeleteUser = (user: any) => {
    setSelectedUserId(user.id);
    setDeleteError(null)
    setOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (selectedUserId !== null) {
      const { success, message } = await deleteUser(selectedUserId);
      if (success) {
        setOpen(false);
        setSelectedUserId(null);
      } else {
        setDeleteError(message);
      }
      fetchUsers()
    }
  };

  const handleCancelDelete = () => {
    setOpen(false);
    setSelectedUserId(null);
    setDeleteError(null);
  };

  const rolesValueFormatter = ({ data, ...props }: { data: any }) => {
    let roles = [...data.roles];
    if (roles.includes("attorney") && roles.includes("filer")) {
      roles = roles.filter((role: string) => role !== "filer");
    }
    if (roles.includes("admin")) {
      roles = ["admin"];
    }
    
    return roles.filter((role: string) => role !== "service_contact")
      .map((role: string) => role.charAt(0).toUpperCase() + role.slice(1)).join(', ');
  }

  const statusCellRender = ({ data, ...props }: { data: any }) => {
    return data.is_activated ? <b className="text-green-600">Activated</b> : <b className="text-red-600"> Not Activated</b>
  }

  const serviceContactValueFormatter = ({ data, ...props }: { data: any }) => data.is_service_contact ? 'Yes' : 'No'

  const actionsCellRender = ({ data, ...props }: { data: any }) => {
    const actions = [];
    if (!data.is_activated) {
      actions.push({
        label: "Re send email",
        onClick: handleResendEmail,
        icon: <EmailIcon className="text-[#5261A0]" />,
      });
    }
    actions.push({
      label: "Edit User",
      onClick: handleEditUser,
      icon: <Edit className="text-[#5261A0]" />,
    });
    actions.push({
      label: "Delete User",
      onClick: handleDeleteUser,
      icon: <GridDeleteIcon className="text-[#5261A0]" />,
    });
    return actions.map((action: any, index: number) => (
      <Tooltip key={index} title={action.label} arrow className="mr-4">
        <button onClick={() => action.onClick(data)}>
          {action.icon}
        </button>
      </Tooltip>
    ))
  }

  const colDef = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "email", headerName: "Email ID", width: 250 },
    { field: "status", headerName: "Status", width: 200, cellRenderer: statusCellRender },
    { field: "serviceContact", headerName: "Service Contact", width: 200, valueFormatter: serviceContactValueFormatter},
    { field: "roles", headerName: "Roles", width: 200, valueFormatter: rolesValueFormatter },
    { field: "actions", headerName: "Actions", width: 200, cellRenderer: actionsCellRender },
  ];

  const handleSyncUsers = async () => {
    try {
      await syncUsers();
      addMessageSuccess('The users where sync');
    } catch(e: any) {
      addMessageError(e.message || 'The users cannot be sync');
    }
  };
  
  const onPaginationChange = (options: PaginationDataRequest) => {
    return fetchUsers({ ...pagination, ...options });
  }

  const onFilterChange = (filters: UserFilters) => {
    return fetchUsers({ ...pagination, ...filters, currentPage: 1 });
  }

  return (
    <div>
      {/* Filters for user search and filtering */}
      <FilterUsers onChange={onFilterChange} onSyncUsers={handleSyncUsers} />

      <AgTable
        data={pagination.data}
        colDef={colDef}
        pagination={pagination}
        fetchMethod={onPaginationChange}
      />

      {/* Delete confirmation dialog */}
      <Dialog open={open} onClose={handleCancelDelete}>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCancelDelete}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className="mt-10">
          <DialogContentText>
            Are you sure you want to <b>delete</b> the user account?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className="mb-8"
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Box className="flex justify-center mb-2">
            <Button
              onClick={handleCancelDelete}
              sx={{
                backgroundColor: "#5261A0",
                color: "white",
                paddingX: "32px",
                borderRadius: "32px",
                marginRight: "16px",
                "&:hover": {
                  backgroundColor: "#405080",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmDelete}
              disabled={Boolean(deleteError)}
              sx={{
                backgroundColor: deleteError ? "#808080" : "#F23131",
                color: "white",
                paddingX: "32px",
                borderRadius: "32px",
                "&:hover": {
                  backgroundColor: deleteError ? "#808080" : "#D92C2C",
                },
              }}
            >
              Delete
            </Button>
          </Box>
          <DialogContentText color="error">{deleteError}</DialogContentText>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UsersTable;
