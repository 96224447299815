import { ReactElement, useEffect, useState } from "react";
import PrivateLayout from "../../components/layout/PrivateLayout";
import StepsWizard, { Step, WizardStep } from "../../components/StepsWizard";
import { Case, EMPTY_CASE,  objectToQuery, Party } from "../../utils/utils";
import { selectCategories, selectCourts, setCaseTypes, setCategories, setCourts, setDamageAmounts, setFilerTypes, setFilingCodes, setPartyTypes, setProcedureRemedies } from "../../components/assets/CaseWizard";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import useAttorneys from "../../hooks/useAttorneys";
import usePaymentAccounts from "../../hooks/usePaymentAccount";
import { useMessageBoard } from "../../components/assets/MessageBoard";
import useConfigs from "../../hooks/useConfigs";
import useFilingCases from "../../hooks/useFilingCases";
import GeneralInfoStep from "./FileCaseSteps/GeneralInfoStep";
import FilingStep from "./FileCaseSteps/FilingStep";
import PartyStep from "./FileCaseSteps/PartyStep";
import SummaryStep from "./FileCaseSteps/SummaryStep";

export const FileCaseScreen = (): ReactElement => {

  const { id } = useParams();

  const { axios } = useAuth({ middleware: 'auth' });

  const {fetchFilingCaseById, saveFilingCase} = useFilingCases()

  const [fcase, setFcase]: [Case | undefined, Function] = useState();

  const courts = useSelector(selectCourts)
  const categories = useSelector(selectCategories)
  const {attorneys, fetchAttorneys, fetchAttorneysList} = useAttorneys()
  const {fetchPaymentAccounts} = usePaymentAccounts()
  
  const navigate = useNavigate();
  const { addMessageError } = useMessageBoard();

  const {configsReady} = useConfigs();

  useEffect(() => {
    (async () => {
      const promises = [];
      let fcase: Case|undefined = EMPTY_CASE;
      if(!id) {
        setFcase(EMPTY_CASE)
      } else {
        promises.push(fetchFcase(parseInt(id)).then((fetchedCase?: Case) => {
          if(fetchedCase?.filing_type != 'new-case') {
            displayError('This is not a new case');
            return;
          }
          fcase = fetchedCase
          if(!['mfr_draft', 'failed', undefined].includes(fetchedCase?.filing_status?.code)) {
            return displayError('Case was already submitted');
          }
        }));
      }
      promises.push(fetchCourts({initial: fcase.initial}))
      promises.push(fetchAttorneysList())
      promises.push(fetchPaymentAccounts())
      await Promise.all(promises)
      
      if(fcase && fcase.location_id) {
        await onCourtChange({id: fcase?.location_id});
      }
      if(fcase && fcase.case_category_id) {
        await onCategoryChange(categories.find((category: any) => category.id == fcase?.case_category_id))
      }
      if(fcase && fcase.case_type_id) {
        await onCaseTypeChange({id: fcase?.case_type_id}, {initial: fcase?.initial})
      }
    })()
  }, []);

  useEffect(() => {
    if(!fcase) {
      return;
    }
    (async () => {
      if(fcase.case_category_id) {
        await onCategoryChange(categories.find((category: any) => category.id == fcase?.case_category_id))
      }
    })()
  }, [categories])

  const displayError = async (message: string) => {
    addMessageError(message)
    navigate("/file-to-court");
    return undefined;
  }

  const fetchFcase = async (case_id?: number): Promise<Case | undefined> => {
    case_id = !fcase?.id ? case_id : fcase?.id
    if(!case_id) {
      return;
    }
    try {
      const filingCase = await fetchFilingCaseById(case_id)
      if(!filingCase) {
        return displayError('Case not found');
      }
      filingCase.case_category_id = filingCase.case_type?.case_category_id
      setFcase(filingCase);
  
      return filingCase;
    } catch(e: any) {
      if(e.response?.status == 404) {
      }
    }
  }

  const saveFcase = async (data: Case) => {
    try {
      const id = await saveFilingCase(data)
      if(id) {
        fetchFcase(id);
      } else {
        fetchFcase()
      }
      // if(!fcase?.id) {
      //   const resultFCase = (await axios.post(`/filing-cases`, data)).data;
      //   await fetchFcase(resultFCase.id);
      //   // await navigate(`/file-case/${resultFCase.id}`, { replace: true });
      // } else {
      //   await axios.put(`/filing-cases/${fcase?.id}`, data);
      //   fetchFcase()
      // }
    } catch(e) {
      return false;
    }
    return true;
  }

  const fetchCourts = async (options: any = {}) => {
    const params = {type: options.initial || fcase?.initial ? 'initial' : 'subsequent'}
    setCourts((await axios.get(`/locations/for-filing-cases?${objectToQuery(params)}`)).data);
  }

  // const fetchAttorneys = async () => {
  //   if (attorneys.length == 0) {
  //     //#TODO: fetch from backend
  //     setAttorneys(mockupListMaker('Attorney'));
  //   }
  // }

  // const fetchPaymentAccounts = async () => {
  //   if (paymentAccounts.length == 0) {
  //     const data = (await axios.get(`/payment-account`)).data;
  //     setPaymentAccounts(data);
  //   }
  // }

  const fetchCategories = async (court: any) => {
    let data:any[] = [];
    if(court?.id) {
      const params = objectToQuery({location_id: court.id});
      data = (await axios.get(`/case-categories?${params}`)).data;
    }
    setCategories(data);
  }
  
  const fetchFilerTypes = async (court: any) => {
    let data:any[] = [];
    if(court?.id) {
      const params = objectToQuery({location_id: court.id});
      data = (await axios.get(`/filer-types?${params}`)).data;
    }
    setFilerTypes(data);
  }
  
  const fetchProcedureRemedies = async (category: any) => {
    let data = []
    if(!!category) {
      const params = objectToQuery({case_category_id: category.id})
      data = (await axios.get(`/procedure-remedies?${params}`)).data
    }
    setProcedureRemedies(data);
  }
  
  const fetchDamageAmount = async (category: any) => {
    let data = []
    if(!!category) {
      const params = objectToQuery({case_category_id: category.id})
      data = (await axios.get(`/damage-amounts?${params}`)).data
    }
    setDamageAmounts(data);
  }

  const fetchCaseTypes = async (category: any) => {
    let data = []
    if(category?.id) {
      const params = objectToQuery({case_category_id: category.id})
      data = (await axios.get(`/case-types?${params}`)).data
    }
    setCaseTypes(data);
  }

  const fetchPartyTypes = async (caseType: any) => {
    let data = [];
    if(caseType?.id) {
      const params = objectToQuery({case_type_id: caseType?.id})
      data = (await axios.get(`/party-types?${params}`)).data;
    }
    setPartyTypes(data);
  }

  const fetchFilingCodes = async (caseType: any, params?: any) => {
    let data:any = [];
    if(caseType) {
      const options: any = {case_type_id: caseType?.id};
      options.initial = (params?.initial ? params?.initial : fcase?.initial) ? 1 : 0
      data = (await axios.get(`/filing-codes?${objectToQuery(options)}`)).data
    }
    data = data.map((filingCode:any) => {
      filingCode.name += filingCode.fee ? ` ($${filingCode.fee})` : ``
      return filingCode;
    })
    setFilingCodes(data);
  }

  const onCourtChange = async (court: any) => {
    fetchCategories(court);
    // fetchPolicies(court);
    fetchFilerTypes(court);
  }

  const onCategoryChange = (category: any) => {
    if (!fcase) {
      return;
    }
    
    if (fcase.initial) {
      if (category?.procedure_remedy_initial && category?.procedure_remedy_initial != 'Not Available') {
        fetchProcedureRemedies(category);
      } else {
        setProcedureRemedies([]);
      }
      if (category?.damage_amount_initial && category?.damage_amount_initial != 'Not Available') {
        fetchDamageAmount(category);
      } else {
        setDamageAmounts([]);
      }
    } else {
      if (category?.procedure_remedy_subsequent && category?.procedure_remedy_subsequent != 'Not Available') {
        fetchProcedureRemedies(category);
      } else {
        setProcedureRemedies([]);
      }
      if (category?.damage_amount_subsequent && category?.damage_amount_subsequent != 'Not Available') {
        fetchDamageAmount(category);
      } else {
        setDamageAmounts([]);
      }
    }

    fetchCaseTypes(category)
  }

  const onCaseTypeChange = (caseType: any, params?: any) => {
    fetchPartyTypes(caseType)
    fetchFilingCodes(caseType, params)
  }

  const steps: Step[] = [
    {
      title: 'Step 1',
      subtitle: 'CASE INFORMATION',
      element: (params: any) => <GeneralInfoStep {...params} />,
      next: async (currentStep: WizardStep) => {
        const errors = await currentStep.props.formik.validateForm();
        currentStep.props.formik.setTouched(errors);
        if (Object.keys(errors).length > 0) {
          return false;
        }
        return await currentStep.props.formik.submitForm();
      }
    }, {
      title: 'Step 2',
      subtitle: 'PARTIES',
      element: (params: any) => <PartyStep {...params} />,
      prev: (currentStep: WizardStep) => {
        const { showingNewPartyForm, disableShowPartyForm } = currentStep.props
        if (showingNewPartyForm) {
          disableShowPartyForm()
        }
        return !showingNewPartyForm
      },
      next: (currentStep: WizardStep) => {
        const { showingNewPartyForm } = currentStep.props
        if (!showingNewPartyForm) {
          const { parties, partyTypes } = currentStep.props
          const requiredPartyTypes = partyTypes.filter((partyType: any) => partyType.is_required).map((partyType: any) => partyType.id);
          const createdPartyTypes = parties.map((partyType: any) => partyType.party_type_id);
          for(const requiredPartyType of requiredPartyTypes) {
            if(!createdPartyTypes.includes(requiredPartyType)) {
              addMessageError('Please create all required parties')
              return false;
            }
          }

          if(!currentStep.props.parties.find((party: Party) => party.filing_party)) {
            addMessageError('At least one party must be the filing one')
            return false;
          }
        }
        return !showingNewPartyForm
      }
    }, {
      title: 'Step 3',
      subtitle: 'FILINGS',
      element: (params: any) => <FilingStep {...params} />,
      prev: (currentStep: WizardStep) => {
        const { filing_files, showingNewFilingForm, disableShowFilingForm } = currentStep.props
        if (showingNewFilingForm) {
          disableShowFilingForm()
        }
        if (filing_files.length === 0) {
          return true;
        }
        return !showingNewFilingForm
      },
      next: (currentStep: WizardStep) => {
        const { showingNewFilingForm } = currentStep.props
        if (!showingNewFilingForm) {
          if (currentStep.props.filing_files.length == 0) {
            addMessageError('Please create the filings')
            return false;
          }
        }
        return !showingNewFilingForm
      }
    }, {
      title: 'Step 4',
      subtitle: 'SUMMARY',
      element: (params: any) => <SummaryStep {...params} />
    }
  ];

  const onTabChange = async (currentStep: WizardStep | undefined, targetStep: WizardStep) => {
    return true;
  }

  if(!configsReady) {
    return <></>;
  }

  return <PrivateLayout>
    <StepsWizard
      steps={steps}
      onTabChange={onTabChange}
      stepParams={{
        fcase,
        fetchFcase,
        saveFcase,
        fetchAttorneys,
        fetchPaymentAccounts,
        onCourtChange,
        onCategoryChange,
        onCaseTypeChange,
        fetchFilingCodes
      }}
    />
  </PrivateLayout>
};