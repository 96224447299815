import { ReactElement, useEffect, useState } from "react";
import FormError from "./FormError";
import Select, { StylesConfig } from "react-select";
import { selectObjectByDot } from "../../utils/utils";

export const selectStyles: StylesConfig = {
  option: (baseStyles, state) => ({
    ...baseStyles,
    position: 'relative',
    bottom: '5px',
    borderColor: 'none',
  }),
  valueContainer: (baseStyles, state) => ({
    ...baseStyles,
    padding: '0px 5px 2px',
    borderColor: 'none',
    bottom: '1px',
    '&:focus': { boxShadow: 'none' },
  }),
  container: (baseStyles, state) => {
    return ({
      ...baseStyles,
      padding: 0,
      border: 'none',
      '&:focus': {
        boxShadow: 'none'
      },
    });
  },
  control: (baseStyles, state) => ({
    ...baseStyles,
    padding: 0,
    minHeight: '36px',
    height: '36px',
    border: state.isFocused ? '1px solid #d1d5db' : '1px solid #e5e7eb',
    '&:hover': {
      borderColor: 'none',
      boxShadow: 'none'
    },
  }),
  input: (baseStyles, state) => ({
    ...baseStyles,
    '&:focus': {
      boxShadow: 'none'
    },
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    '&:focus': { boxShadow: 'none' },
    position: 'relative',
    bottom: '2px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    // position: 'relative',
    // bottom: '2px',
  }),
  menu: (provided) => ({
    ...provided,
    top: '30px'
  }),
};

const FormSelect = ({ name, formik, options, optionValue = 'id', optionLabel = 'name', searchable = false, clearable = true, ...props }: any): ReactElement => {
  const [_options, setOptions] = useState([]);

  useEffect(() => {
    if(!options) {
      return;
    }
    setOptions(options.map((option:any) => {
      return {
        value: option,
        label: getOptionLabel(option)
      }
    }));
  }, [options]);

  const getOptionValue = (option: any) => {
    if(!option) {
      return;
    }
    if(typeof optionValue === 'function') {
      return optionValue(option);
    }
    return `${option[optionValue] ? option[optionValue] : option}`
  }

  const getOptionLabel = (option: any) => {
    if(!option) {
      return;
    }
    if(typeof optionLabel === 'function') {
      return optionLabel(option);
    }
    return `${option[optionLabel] ? option[optionLabel] : option}`
  }

  const getOption = (value: any) => {
    if(!formik && !_options || _options.length == 0) {
      return null;
    }
    for(let i in _options) {
      let option:any = _options[i]
      if(value == getOptionValue(option.value)) { 
        return option;
      }
    }
    return null;
  }

  const onChange = (value: any) => {
    console.log(value)
    if (props.onChange) {
      props.onChange(value?.value, value);
    }
    if(!!formik) {
      formik.setFieldValue(name, getOptionValue(value?.value));
    }
  }

  const onBlur = (event: any) => {
    if (props.onBlur) {
      props.onBlur(event);
    }
    if(!!formik) {
      return formik.handleBlur(event)
    }
  }

  return <>
    <Select
      {...{
        ...props,
        name,
        onChange,
        onBlur,
        options: _options,
        isSearchable: searchable,
        isClearable: clearable,
        value: getOption(selectObjectByDot(formik?.values, name)),
        // classNames: {
        //   control: (state) =>('border-grey-300')
        // },
        styles: selectStyles
      }}
    />
    {
      formik && <FormError formik={formik} name={name} />
    }
  </>
  // return <>
  //   {
  //     searchable && <Select
  //       {...{
  //         ...props,
  //         name,
  //         onChange: onChangeSearchable,
  //         onBlur,
  //         options: data(),
  //         // value: getOption(formik.values[name]),
  //         // className: "px-2r w-full border rounded-md tracking-wider hover:border-gray-300 focus:outline-none focus:border-gray-300 bg-white" + (props.className ? ` ${props.className}` : ''),
  //         // style: { paddingTop: '7px', paddingBottom: '7px', height: '31px', ...props.style }
  //       }} />
  //   }
  //   {
  //     !searchable && <select
  //       {...{
  //         ...props,
  //         name,
  //         onChange,
  //         onBlur,
  //         value: formik.values[name],
  //         className: "px-2 w-full border rounded-md tracking-wider hover:border-gray-300 focus:outline-none focus:border-gray-300 bg-white" + (props.className ? ` ${props.className}` : ''),
  //         style: { paddingTop: '9px', paddingBottom: '9px', height: '35px', ...props.style }
  //       }}
  //     >
  //       {
  //         empty && <option value="">{typeof empty == 'string' ? empty : 'Select an option...'}</option>
  //       }
  //       {
  //         _options && _options.map(option => {
  //           const value = getOptionValue(option);
  //           const label = getOptionLabel(option);
  //           return <option value={value} key={value}>
  //             {label}
  //           </option>
  //         })
  //       }
  //     </select>
  //   }
  //   <FormError formik={formik} name={name} />
  // </>
}
export default FormSelect;