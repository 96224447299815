import { IMapping } from "../../../interfaces";
import { toUpperCaseFirst } from "../../../utils/utils";

const textColors: IMapping = {
  'Draft': 'text-sky-600',
  'Failed': 'text-red-500',
  'Submitting': 'text-sky-600',
  'Submitted': 'text-amber-400',
  'Accepted': 'text-lime-600',
  'Rejected': 'text-red-500',
  'Reviewing': 'text-base-blue',
  'Under Review': 'text-base-blue',
  'Reviewed': 'text-[#FF8933]',
  'Served': 'text-lime-600',
  '': 'text-primary',
}
const bgColors: IMapping = {
  'Draft': 'bg-sky-600',
  'Failed': 'bg-red-500',
  'Submitting': 'bg-sky-600',
  'Submitted': 'bg-amber-400',
  'Accepted': 'bg-lime-600',
  'Rejected': 'bg-red-500',
  'Reviewing': 'text-base-blue',
  'Under Review': 'text-base-blue',
  'Reviewed': 'bg-[#FF8933]',
  'Served': 'bg-lime-600',
  '': 'bg-primary',
}

export const colors: IMapping = {
  'mfr_draft': 'sky-600',
  'mfr_submitted': 'amber-400',
  'draft': 'sky-600',
  'failed': 'red-500',
  'submitting': 'sky-600',
  'submitted': 'amber-400',
  'accepted': 'lime-600',
  'rejected': 'red-500',
  'reviewing': 'base-blue',
  'under-review': 'base-blue',
  'reviewed': '[#FF8933]',
  'served': 'lime-600',
  '': 'primary'
}

export const getKey = (status: any) => {
  if (typeof status == 'string') {
    return status.toLowerCase();
  }
  return status.code;
}

export const getText = (status: any) => {
  if (typeof status == 'string') {
    return toUpperCaseFirst(status);
  }
  return status.short_name;
}

export const textColorByStatus = (status: string) => {
  return `text-${colors[getKey(status)]}`;
};

export const bgColorByStatus = (status: string) => {
  return `bg-${colors[getKey(status)]}`;
};