import React, { Ref, RefObject, useCallback, useEffect, useState } from 'react'
import { Select, IconButton, MenuItem, SelectChangeEvent, FormControl } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { AgGridReact } from 'ag-grid-react';
import { PaginationData, PaginationDataRequest, PaginationDataResponse, RemoteFetchMethod } from '.';

function setText(selector: string, text: any) {
  (document.querySelector(selector) as any).innerHTML = text;
}

interface CustomPaginatorProps {
  agRef: RefObject<AgGridReact>
  fetchMethod: RemoteFetchMethod
  pagination: PaginationDataResponse
  pageSizeSelectorOptions?: number[]
};

const AgCustomRemotePaginator = ({ agRef, pagination, fetchMethod, pageSizeSelectorOptions = [5, 10, 25] }: CustomPaginatorProps) => {

  const handleChange = (event: SelectChangeEvent) => {
    fetchMethod({ ...pagination, currentPage: 1, perPage: parseInt(event.target.value) })
  };

  const onBtPrevious = useCallback(() => {
    if (pagination.currentPage <= 1) {
      return;
    }
    fetchMethod({ ...pagination, currentPage: pagination.currentPage - 1 });
  }, [pagination]);

  const onBtNext = useCallback(() => {
    if (pagination.currentPage >= pagination.lastPage) {
      return;
    }
    fetchMethod({ ...pagination, currentPage: pagination.currentPage + 1 });
  }, [pagination]);

  return (
    <div className="flex flex-row justify-end items-center gap-4">
      <p>Rows per page:</p>
      <FormControl variant="standard" sx={{ m: 1, minWidth: 50 }}>
        <Select
          value={`${pagination?.perPage}`}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {pageSizeSelectorOptions?.map((value: number) => <MenuItem value={value} key={value}>{value}</MenuItem>)}
        </Select>
      </FormControl>
      {
        !!pagination?.total && <div>{pagination?.from}-{pagination?.to} of {pagination?.total}</div>
      }
      {
        !pagination?.total && <div>No results</div>
      }
      <IconButton
        disabled={pagination.currentPage <= 1}
        onClick={onBtPrevious}
        aria-label="Go to previous page"
      >
        <KeyboardArrowLeftIcon />
      </IconButton>
      <IconButton
        disabled={pagination.currentPage >= pagination.lastPage}
        onClick={onBtNext}
        aria-label="Go to next page"
      >
        <KeyboardArrowRightIcon />
      </IconButton>
    </div>
  );
}

export default AgCustomRemotePaginator;