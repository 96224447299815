import { EditOutlined, VisibilityOutlined } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useFilings from '../../../hooks/useFilings';
import AgTable, { PaginationDataRequest } from '../../../components/table/AgTable';
import dayjs from 'dayjs';
import StatusChip from '../../../components/table/StatusChip';
import { DateRange, DateRangeInput } from '../../../components/Inputs/DateRangeInput';
import FilingsFilterInput, { FilingsFilter } from '../../../components/Inputs/FilingsFilterInput';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import FilterSearchBar from '../../../components/FilterSearchBar';
import SyncFilingsButton from '../../my-filings/components/SyncFilingsButton';

const DraftFilingsTab = () => {
  const { pagination, fetchEServiceMyFilings, deleteFiling } = useFilings();
  const navigate = useNavigate();

  // Fetch draft filings whenever filters change
  useEffect(() => {
    fetchEServiceMyFilings();
  }, []);

  const handleViewFiling = (data: any) => {
    navigate(`/sent-service/${data.id}`);
  };

  const handleEditFiling = (data: any) => {
    navigate(`/e-service/${data.filing_case.id}`);
  };

  const actions = (status: any) => {
    const baseActions = [
      {
        label: 'View',
        onClick: handleViewFiling,
        icon: <VisibilityOutlined className="text-base-blue" />,
      }
    ];

    if (status === 'failed') {
      baseActions.push({
        label: 'Edit',
        onClick: handleEditFiling,
        icon: <EditOutlined className="text-base-blue" />,
      });
    }

    return baseActions;
  };

  const colDef: Array<Object> = [
    { field: 'filing_case.filing_case_envelope.envelope_id', headerName: 'Envelope N', flex: 1 },
    { field: 'filing_case.docket_id', headerName: 'Case N', flex: 1 },
    { field: 'client_number', headerName: 'Client Matter N', flex: 1 },
    {
      field: 'filing_case.filing_status',
      headerName: 'Filing Status',
      cellRenderer: (data: any) => (
        <StatusChip status={data.value} type='borderLess' />
      ),
    },
    {
      field: 'updated_at',
      headerName: 'Last Update',
      valueFormatter: (params: any) => {
        return dayjs(params.value).format('MM/DD/YYYY, HH:mm');
      }
    },
    {
      field: 'filing_case.filing_status.code',
      headerName: 'Actions',
      maxWidth: 120,
      cellRenderer: (data: any) => (
        <div className="flex space-x-2">
          {actions(data.value).map((action: any, index: number) => {
            return (
              <Tooltip key={index} title={action.label} arrow>
                <button onClick={() => action.onClick(data.data)}>
                  {action.icon}
                </button>
              </Tooltip>);
          })}
        </div>
      )
    },
  ];

  const onSearchChange = (search: string) => {
    return fetchEServiceMyFilings({ ...pagination, currentPage: 1, search });
  }

  const onDateChange = (dateRange: DateRange) => {
    return fetchEServiceMyFilings({ ...pagination, ...dateRange, currentPage: 1 });
  }

  const onFilterChange = (filters: FilingsFilter) => {
    return fetchEServiceMyFilings({ ...pagination, ...filters, currentPage: 1 });
  }

  const onPaginationChange = (options: PaginationDataRequest) => {
    return fetchEServiceMyFilings({ ...pagination, ...options });
  }

  return <>
    <div className="flex flex-col space-y-4 mb-8">
      <FilterSearchBar onChange={onSearchChange} placeholder="Search by Client Matter N, Case N, Case Title, or Court Location..."/>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
        <div className="flex items-center justify-between">
          <DateRangeInput onChange={onDateChange} value={pagination} />
          <FilingsFilterInput onChange={onFilterChange} value={pagination} />
          <div className='flex-1 text-right'>
            <SyncFilingsButton afterSync={fetchEServiceMyFilings}/>
          </div>
        </div>
      </LocalizationProvider>
    </div>
    <div className="mb-8">
      <AgTable
        data={pagination.data}
        colDef={colDef}
        pagination={pagination}
        fetchMethod={onPaginationChange}
      />
    </div>
  </>
};

export default DraftFilingsTab;
