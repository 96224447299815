import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { UserFilters } from "../../../../hooks/useUsers";
import { useAuth } from "../../../../hooks/auth";
import FilterSearchBar from "../../../../components/FilterSearchBar";

interface FilterUsersProps {
  onChange: (filters: UserFilters) => void
  onSyncAttorneys?: Function
  onCreateAttorney?: Function
}

const FilterAttorneys: React.FC<FilterUsersProps> = ({ onChange, onSyncAttorneys, onCreateAttorney }) => {
  const [search, setSearch] = useState<UserFilters["search"]>("");
  const { hasRole } = useAuth({ middleware: 'auth' });

  useEffect(() => {
    const options: any = {
      search: search ? search : ''
    };
    onChange(options);
  }, [search]);

  return (
    <div>
      <FilterSearchBar onChange={setSearch} placeholder="Search by Name, Email ID..."/>
      <div className="flex justify-between items-center w-full my-4">
        <div className="flex flex-initial">
        </div>
        <div className="flex-1 flex justify-end gap-4">
          {/* Sync users */}
          {
            onSyncAttorneys && hasRole('admin') && <Button
              variant="outlined"
              sx={{
                border: "solid 1px #5261A0",
                backgroundColor: "white",
                // marginBottom: "16px",
                color: "#5261A0",
                paddingX: "32px",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#5261A0",
                  color: "white",
                },
              }}
              onClick={() => onSyncAttorneys()}
            >
              Sync Attorneys
            </Button>
          }
          {
            onCreateAttorney && <Button
              variant="contained"
              sx={{
                backgroundColor: "#5261A0",
                color: "white",
                textTransform: "none",
              }}
              onMouseDown={() => onCreateAttorney()}
            >
              Add New Attorney
            </Button>
          }
        </div>
      </div>
    </div>
  );
};

export default FilterAttorneys;
