import React, { useState, useEffect } from 'react';
import { Button, Popover, FormControl, FormControlLabel, Checkbox, TextField, Collapse } from '@mui/material';
import { ExpandMore, Tune } from '@mui/icons-material';
import 'dayjs/locale/en';
import StatusChip from '../table/StatusChip';
import useAttorneys from '../../hooks/useAttorneys';
import FormSelect from '../forms/FormSelect';
import { Attorney, makeName } from '../../utils/utils';
import { useFormik } from 'formik';

export type FilterType = 'statuses' | 'envelope' | 'caseNumber' | 'clientMatterNumber' | 'attorneyId';

interface FilterFilingPanelProps {
  onChange? : (filters: any) => void
  filters?: FilterType[]
  value: FilingsFilter
}

export interface FilingsFilter {
  statuses?: string[]
  envelope?: string
  caseNumber?: string
  clientMatterNumber?: string
  attorneyId?: string
}

const emptyFilter: FilingsFilter = {
  statuses: [],
  envelope: '',
  caseNumber: '',
  clientMatterNumber: '',
  attorneyId: ''
};

const baseFilters: {
  label: string,
  key: FilterType,
  renderElement?: React.FC
  renderValue?: React.FC
}[] = [
    {
      label: 'Filing Status',
      key: 'statuses',
      renderElement: ({...props}) => <FilingsFilterStatusInput {...props}/>,
      renderValue: ({...props}) => <FilingsFilterStatusValue {...props}/>
    },
    {
      label: 'Filing Attorney',
      key: 'attorneyId',
      renderElement: ({...props}) => <FilingsFilterAttorneyInput {...props}/>,
      renderValue: ({...props}) => <FilingsFilterAttorneyValue {...props}/>
    },
    { label: 'Envelope N', key: 'envelope' },
    { label: 'Case Number', key: 'caseNumber' },
    { label: 'Client Matter Number', key: 'clientMatterNumber' }
  ];

const allStatuses = {
  submitting: 'Submitting',
  failed: 'Failed',
  submitted: 'Submitted',
  accepted: 'Accepted',
  rejected: 'Rejected',
};

const closedExpanded = {
  statuses: false,
  envelope: false,
  caseNumber: false,
  clientMatterNumber: false,
  filingAttorney: false,
};

const areDifferent = (filter1: FilingsFilter, filter2: FilingsFilter) => {
  const statuses1 = [...(filter1.statuses ? filter1.statuses : [])].sort().join('|');
  const statuses2 = [...(filter2.statuses ? filter2.statuses : [])].sort().join('|');
  if(statuses1 != statuses2) {
    return true;
  }

  const keys: FilterType[] = ['envelope', 'caseNumber', 'clientMatterNumber', 'attorneyId'];
  for(let i of keys) {
    if(filter1[i] != filter2[i]) {
      return true;
    }
  }
  
  return false;
}

// -------------------------------

const FilingsFilterDefaultValue: React.FC = ({ value }: any) => {
  return <>
    {value}
  </>
}

const FilingsFilterDefaultElement: React.FC = ({ value, onChange, filters }: any) => {
  return <TextField
    value={value}
    onChange={(e) => onChange(e.target.value)}
    // variant="outlined"
    className="w-full"
    sx={{
      '& .MuiInputBase-input': {
        padding: '6px 8px'
      },
    }}
  />
}

// -------------------------------

const FilingsFilterStatusValue: React.FC = ({ value }: any) => {
  if(value.length == Object.entries(allStatuses).length) {
    return '';
  }
  return <div style={{fontSize: '70%'}}>
    {
      value.map((status: string) => <span className='mr-1 relative' key={status} style={{top: '2px'}}>
        <StatusChip
          status={status}
          type='borderLess'
        />
      </span>)
    }
  </div>
}

const FilingsFilterStatusInput: React.FC = ({ value, onChange, filters }: any) => {
  const {attorneys, fetchAttorneysList} = useAttorneys();

  useEffect(() => {
    if(attorneys.length) {
      return;
    }
    fetchAttorneysList();
  }, []);

  const onStatusChange = (status: string, checked: boolean) => {
    let statuses: string[] = value ? value : [];
    if(checked) {
      statuses.push(status);
    } else {
      statuses = statuses?.filter((st: any) => status != st);
    }
    onChange(statuses);
  };

  return <div className="flex flex-col gap-1 p-2">
  {Object.entries(allStatuses).map(([statusKey, status]) => (
    <FormControlLabel
      key={statusKey}
      style={{height: '30px'}}
      control={
        <Checkbox
          checked={value?.includes(statusKey)}
          onChange={(e: any) => onStatusChange(statusKey, e.target.checked)}
          color="primary"
          className='relative'
          style={{bottom: '2px'}}
        />
      }
      label={status}
    />
  ))}
</div>
}

// -------------------------------

const FilingsFilterAttorneyValue: React.FC = ({ value }: any) => {
  const { attorneys } = useAttorneys();

  return <>
    {makeName(attorneys.find((attorney: Attorney) => attorney.id == value))}
  </>
}

const FilingsFilterAttorneyInput: React.FC = ({ value, onChange, filters }: any) => {
  const {attorneys, fetchAttorneysList} = useAttorneys();

  useEffect(() => {
    if(attorneys.length) {
      return;
    }
    fetchAttorneysList();
  }, []);

  const onAttorneySelect = (attorney: any) => {
    onChange(attorney?.id);
    // formik.setFieldValue('attorney_id', attorney?.id);
  };

  const formik = useFormik({
    initialValues: {
      attorney_id: value //#TODO: ver esto setear el valor del attorney no persiste
    },
    onSubmit: (values: any) => {

    }
  });

  return <FormSelect
    formik={formik}
    name="attorney_id"
    options={attorneys}
    optionValue="id"
    optionLabel={makeName}
    empty="Select Attorney"
    placeholder="Select Attorney..."
    onChange={onAttorneySelect}
    searchable={true}
  />
}

// -------------------------------

const FilingsFilterInput: React.FC<FilterFilingPanelProps> = ({ value, onChange, filters }) => {

  const [valueOriginal, setValueOriginal] = useState<FilingsFilter>({ ...emptyFilter });
  const [valueNew, setValueNew] = useState<FilingsFilter>({ ...emptyFilter });
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({...closedExpanded});
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    if(areDifferent(valueOriginal, valueNew)) {
      onChange && onChange(valueNew);
      setValueOriginal(valueNew);
    }
  };

  const handleClearFilters = async () => {
    setAnchorEl(null);
    setValueOriginal({ ...emptyFilter });
    setValueNew({ ...emptyFilter });
    if (areDifferent(valueOriginal, emptyFilter)) {
      onChange && onChange({ ...emptyFilter });
    }
  }

  useEffect(() => {
    for(const baseFilter of baseFilters) {
      if(!baseFilter.renderValue) {
        baseFilter.renderValue = ({...props}) => <FilingsFilterDefaultValue {...props}/>
      }
      if(!baseFilter.renderElement) {
        baseFilter.renderElement = ({...props}) => <FilingsFilterDefaultElement {...props}/>
      }
    }
  }, [])
  useEffect(() => {
    if (!value) {
      return;
    }
    setValueOriginal({...value, statuses: [...(value.statuses ? value.statuses : [])]});
    setValueNew({...value, statuses: [...(value.statuses ? value.statuses : [])]});
  }, [value]);

  const handleFieldChange = (key: string, value: string) => {
    setValueNew({...valueNew, [key]: value});
  };

  const handleToggleExpand = (key: string) => {
    setExpanded({...closedExpanded, [key]: !expanded[key]});
  };

  return <>
    <Button
      endIcon={<Tune />}
      onClick={handlePopoverOpen}
      sx={{
        borderRadius: '50px',
        borderColor: '#5261A0',
        color: '#5261A0',
        padding: '6px 16px',
        textTransform: 'none',
        fontWeight: 'bold',
      }}
    >
      More Filters
    </Button>
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handlePopoverClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      sx={{
        borderRadius: '25px'
      }}
    >
      <div className="p-8 w-[364px]">
        <div className="flex flex-row justify-between items-center mb-4 rounded-xl">
          <span className="font-bold text-base">Filter By:</span>
          <Button
            onClick={handleClearFilters}
            variant="outlined"
            sx={{
              fontSize: '0.75rem',
              borderColor: '#3E4A7F',
              color: '#3E4A7F',
              borderRadius: '50px',
              textTransform: 'none',
              // fontWeight: 'bold',
              // padding: '6px 24px',
              // marginTop: '16px',
            }}
          >
            Clear Filter
          </Button>
        </div>
        <div className="flex flex-col">
          {
            /* Filters */
            baseFilters.filter((baseFilter) => !filters || filters.includes(baseFilter.key)).map(({ label, key, renderElement, renderValue }) => <FormControl fullWidth variant="outlined" key={key}>
              <div
                onClick={() => handleToggleExpand(key)}
                className="flex items-center justify-between cursor-pointer border-1 py-1 px-3"
                style={{ minHeight: '50px' }}
              >
                <div className='flex flex-col'>
                  <span className="font-light text-sm">{label}</span>
                  <span className="font-light text-sm empty:content-['as'] text-blue-900" >
                    { renderValue && renderValue({value: valueNew[key]}) }
                  </span>
                </div>
                <ExpandMore />
              </div>
              <Collapse in={expanded[key]}>
                {
                  renderElement && renderElement({
                    value: valueNew[key],
                    onChange: (value: any) => handleFieldChange(key, value)
                  })
                }
              </Collapse>
            </FormControl>
            )
          }
        </div>
        <div className='flex flex-row justify-center'>
          <Button
            onClick={handlePopoverClose}
            variant="contained"
            sx={{
              backgroundColor: '#5261A0',
              color: 'white',
              borderRadius: '50px',
              textTransform: 'none',
              fontWeight: 'bold',
              padding: '6px 24px',
              marginTop: '16px',
              '&:hover': {
                backgroundColor: '#3E4A7F',
              },
            }}
          >
            Apply
          </Button>
        </div>
      </div>
    </Popover>
  </>
};

export default FilingsFilterInput;
