import React, { useState, useEffect } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { GridSearchIcon } from '@mui/x-data-grid';
import { useDebounce } from '../hooks/useDebounce';

interface FilterSearchBarProps {
  onChange: (searchTerm: string) => void
  placeholder?: string
}

const FilterSearchBar: React.FC<FilterSearchBarProps> = ({ onChange, ...props }) => {
  const [search, setSearch] = useState<null|string>(null);
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    if(debouncedSearch === null) {
      return;
    }
    onChange(debouncedSearch);
  }, [debouncedSearch]);

  return (
    <TextField
      value={search}
      onChange={(e) => setSearch(e.target.value)}
      variant="outlined"
      fullWidth
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <div className="bg-base-blue p-2 rounded-full">
              <GridSearchIcon className="text-white" />
            </div>
          </InputAdornment>
        ),
        classes: {
          notchedOutline: 'border-none',
        },
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          borderRadius: '50px',
        },
      }}
      className="w-full text-black"
      {...props}
    />
  );
};

export default FilterSearchBar;
